<template>
  <div class="full-size pos-relative">
    <block-loading v-if="loading"
                   tip=""
                   color="rgba(250, 250, 250, 0.6)"/>
    <a-form-model ref="modelRef"
                  :model="form"
                  :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                  class="form-model">
      <a-form-model-item label="内训主题"
                         prop="title">
        <a-input v-model="form.title"
                 :disabled="published"
                 allow-clear
                 placeholder="请输入内训主题"/>
      </a-form-model-item>
      <a-form-model-item label="开始时间"
                         prop="start_day">
        <a-date-picker v-model="form.start_day"
                       :disabled="published"
                       :disabled-date="disabledStartDate"
                       :show-time="{ format: 'HH:mm' }"
                       :disabled-time="disabledStartTime"
                       format="YYYY-MM-DD HH:mm"/>
      </a-form-model-item>
      <a-form-model-item label="结束时间"
                         prop="end_day">
        <a-date-picker v-model="form.end_day"
                       :disabled="published"
                       :disabled-date="disabledEndDate"
                       :show-time="{ format: 'HH:mm' }"
                       :disabled-time="disabledEndTime"
                       format="YYYY-MM-DD HH:mm"/>
      </a-form-model-item>
      <a-form-model-item label="岗位" prop="post_ids_str">
        <select-job-grouped v-model="form.post_ids_str"
                            :disabled="published"></select-job-grouped>
      </a-form-model-item>
      <a-form-model-item label="组织机构"
                         prop="org_ids_str"
                         class="org-wrapper">
        <select-org v-model="form.org_ids_str"
                    :disabled="published"
                    :show-label="false"></select-org>
      </a-form-model-item>
      <a-form-model-item label="主题合理性评价">
        <a-switch v-model="form.is_subjective"
                  :disabled="published"></a-switch>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{span: 24}">
        <a-button v-if="published"
                  @click="next">下一页
        </a-button>
        <template v-else>
          <a-button type="primary"
                    @click="saveAndNext">保存并下一步
          </a-button>
          <a-button type="primary"
                    @click="save">保存本页
          </a-button>
        </template>
        <a-button @click="back">返回列表</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { menus as unifiedMenus } from '../unified/Detail.vue';
import { menus as independentMenus } from '../independent/Detail.vue';

import {
  createUnifiedTrainingBasic,
  getUnifiedTrainingBasic,
} from '@/api/training/Index';

import BlockLoading from '@/components/BlockLoading.vue';
import SelectOrg from '@/components/SelectOrg.vue';
import SelectJobGrouped from '@/components/SelectJobGrouped';

export default {
  name: 'CreateTrainingBasic',
  components: {
    BlockLoading,
    SelectOrg,
    SelectJobGrouped,
  },
  props: {
    trainingType: { type: String, default: '1' },
  },
  computed: {
    ...mapState({}),
    menus() {
      switch (+this.trainingType) {
        case 1:
          return unifiedMenus;
        case 2:
          return independentMenus;
        default:
          return [];
      }
    },

    disabledStartDate() {
      return (current) => {
        if (!current) {
          return false;
        }
        if (current < moment().startOf('days')) {
          return true;
        }
        return !!(this.form?.end_day && current.isAfter(this.form.end_day, 'days'));
      };
    },
    disabledEndDate() {
      return (current) => {
        if (!current) {
          return false;
        }
        if (current < moment().startOf('days')) {
          return true;
        }
        return !!(this.form?.start_day && current.isBefore(this.form.start_day, 'days'));
      };
    },
    disabledStartTime() {
      return (current) => ({
        disabledHours: () => {
          if (!current) {
            return [];
          }
          if (current.isSame(moment(), 'days')) {
            return this.range(0, moment().hours());
          }
          if (this.form?.end_day && current.isSame(this.form.end_day, 'days')) {
            const start = this.form.end_day.hours();
            return this.range(start <= 0 ? 0 : start + 1, 24);
          }
          return [];
        },
        disabledMinutes: () => {
          if (!current) {
            return [];
          }
          if (current.isSame(moment(), 'hours')) {
            return this.range(0, moment().minutes());
          }
          if (this.form?.end_day && current.isSame(this.form.end_day, 'hours')) {
            return this.range(this.form.end_day.minutes(), 60);
          }
          return [];
        },
      });
    },
    disabledEndTime() {
      return (current) => ({
        disabledHours: () => {
          if (!current) {
            return [];
          }
          if (current.isSame(moment(), 'days')) {
            return this.range(0, moment().hours());
          }
          if (this.form?.start_day && current.isSame(this.form.start_day, 'days')) {
            const end = this.form.start_day.hours();
            return this.range(0, end <= 0 ? 0 : end);
          }
          return [];
        },
        disabledMinutes: () => {
          if (!current) {
            return [];
          }
          if (current.isSame(moment(), 'hours')) {
            return this.range(0, moment().minutes());
          }
          if (this.form?.start_day && current.isSame(this.form.start_day, 'hours')) {
            return this.range(0, this.form.start_day.minutes() + 1);
          }
          return [];
        },
      });
    },
    range() {
      return (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };
    },
  },
  data() {
    let checkDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(rule.message));
      }
      if (this.form.start_day
        && this.form.end_day
        && !this.form.end_day.isAfter(this.form.start_day, 'minutes')) {
        return callback(new Error('开始时间要小于结束时间'));
      } else {
        return callback();
      }
    };
    return {
      loading: false,
      id: 0,
      published: true,

      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: {
        title: '',
        start_day: undefined,
        end_day: undefined,
        post_ids_str: '',
        org_ids_str: '',
        is_subjective: true,
      },
      rules: {
        title: [
          { required: true, trigger: 'blur', message: '请填写内训主题', whitespace: true },
        ],
        start_day: [
          { required: true, trigger: 'change', message: '请选择开始时间' },
          { trigger: 'change', validator: checkDate },
        ],
        end_day: [
          { required: true, trigger: 'change', message: '请选择结束时间' },
          { trigger: 'change', validator: checkDate },
        ],
        post_ids_str: [
          { required: true, trigger: 'changed', message: '请选择岗位' },
        ],
        org_ids_str: [
          { required: true, trigger: 'change', message: '请选择组织机构' },
        ],
      },
    };
  },
  created() {
    this.id = +this.$route.query?.id || 0;

    this.published = !!this.id;
    this.getBasic();
  },
  methods: {
    back() {
      this.$confirm({
        title: '提示',
        content: '确认返回列表？',
        onOk: () => this.$router.back(),
      });
    },

    async getBasic() {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const data = await getUnifiedTrainingBasic(this.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || {};
      this.published = !!data?.data?.is_publish;
      this.form = {
        title: result.title,
        start_day: result.starttime_1
          ? moment(result.starttime_1, 'YYYY-MM-DD HH:mm')
          : undefined,
        end_day: result.endtime_1
          ? moment(result.endtime_1, 'YYYY-MM-DD HH:mm')
          : undefined,
        post_ids_str: result.training_post_ids?.length
          ? result.training_post_ids.join(',')
          : '',
        org_ids_str: result.push_org_ids_arr?.length
          ? result.push_org_ids_arr.join(',')
          : '',
        is_subjective: !!+result.is_subjective,
      };
    },

    validate() {
      if (this.loading) {
        return Promise.reject(new Error('Loading'));
      }
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.$refs.modelRef.validate((validator) => {
          if (validator) {
            resolve();
          } else {
            this.loading = false;
            reject();
          }
        });
      });
    },

    save() {
      return this.validate().then(async () => {
        const params = {
          type: this.trainingType,
          ...this.form,
          start_day: this.form.start_day.format('YYYY-MM-DD HH:mm'),
          end_day: this.form.end_day.format('YYYY-MM-DD HH:mm'),
          is_subjective: this.form.is_subjective ? 1 : 0,
        };
        if (this.id) {
          params.id = this.id;
        }
        const data = await createUnifiedTrainingBasic(params).finally(() => this.loading = false);
        if (!data || data.error_code) {
          this.$message.error(data?.message || `保存失败`);
          return Promise.reject(data);
        }

        this.$message.success(data?.message || `保存成功`);
        if (!this.id) {
          this.id = +data.data?.training_id || 0;
          await this.$router.replace({
            path: this.$route.path,
            query: { id: this.id },
          });
        }
        return data.data;
      });
    },

    saveAndNext() {
      this.save().then(() => this.next());
    },
    next() {
      let routePath = this.$route.path?.split('?')[0];

      let num = 0;
      for (let index = 0; index < this.menus.length; index++) {
        if (routePath === this.menus[index].path) {
          num = ++index;
        }
      }
      const path = this.menus[num]?.path || '';
      this.$router.replace({
        path,
        query: { id: this.id },
      });
    },

  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-col.ant-form-item-label {
  max-width: 180px;
}

.org-wrapper ::v-deep .ant-form-explain {
  padding-top: 10px !important;
}
</style>
