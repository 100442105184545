import request from '@/utils/Request';


//内训配置 -- 获取全部岗位
export function getAllPost() {
    return request({
        url: `/internaltraining/admin/config/getAllPost`,
        method: 'get',

    })
}
//内训配置 -- 设置内训配置岗
export function postSetConfPost(data) {
    return request({
        url: `/internaltraining/admin/config/setConfPost`,
        method: 'post',
        data
    })
}

//内训配置 -- 设置管理岗
export function postSetManagePost(data) {
    return request({
        url: `/internaltraining/admin/config/setManagePost`,
        method: 'post',
        data
    })
}
