<template>
  <div class="full-size">
    <CommonTitle :titles="titles"/>

    <a-collapse default-active-key="1" :bordered="false">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0"/>
      </template>
      <a-collapse-panel key="1" :header="trainingTitle" :style="customStyle">
        <p>内训主题：{{ trainingTitle }}</p>
        <p>内训类型：{{ trainingType }}</p>
        <p class="mb-0">内训时间：{{ trainingDate }}</p>
      </a-collapse-panel>
    </a-collapse>

    <a-form-model layout="inline" class="pd-card">
      <a-form-model-item label="体验中心名称">
        <select-org v-model="orgIds" :show-label="false"/>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button @click="onReset">重置</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="exportExcel">导出</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="viewRules">调整打分规则</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="back">返回</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="flex pd-card">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">

        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="viewMaterial(record)">查看提交材料</a-button>
          <a-button type="link" @click="viewStudentDetail(record)">学员详情</a-button>
          <a-button type="link" @click="evaluate(record)">
            <span v-if="isSettlement">查看评分</span>
            <span v-else>评价打分</span>
          </a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <grade-modal ref="gradeModalRef" @changed="onRefreshTable"/>
    <full-content-modal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/utils/Common';
import {
  getTrainingScoreList,
  getTrainingScoreListDownload,
  getUnifiedTrainingBasic,
} from '@/api/training/Index';

import CommonTitle from '@components/CommonTitle.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';
import SelectOrg from '@/components/SelectOrg.vue';
import FullContentModal from '../../components/FullContentModal.vue';
import GradeModal from '../../components/GradeModal';

export default {
  name: 'ScoreList',
  components: {
    CommonTitle,
    FullContentModal,
    GradeModal,
    Pagination,
    SelectOrg,
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '内训计划列表' }, { title: '内训评分表' }],
      loading: false,
      downloading: false,
      id: 0,
      orgIds: '',

      trainingTitle: '',
      trainingType: '',
      trainingDate: '',
      customStyle: 'background: #f7f7f7; border-radius: 4px; margin-bottom: 5px; border: 0; overflow: hidden',

      isSettlement: false,

      columns: [],
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;
    this.orgIds = this.$route.query?.o_id || '';

    this.getUnifiedTrainingBasic();
    this.onSearch();
  },
  methods: {
    async getUnifiedTrainingBasic() {
      if (!this.id) {
        return;
      }
      const data = await getUnifiedTrainingBasic(this.id);
      if (!data?.error_code) {
        const result = data?.data || {};
        this.trainingTitle = result.title || '';
        this.trainingType = result.type_title || '';
        this.trainingDate = `${result.starttime_1} 至 ${result.endtime_1}`;
        this.isSettlement = result?.is_settlement === 2;
      }
    },
    back() {
      this.$router.back();
    },
    onReset() {
      this.orgIds = '';

      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshTable() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        page: page,
        per_page: pageSize,
        org_ids: this.orgIds,
      };
      const data = await getTrainingScoreList(this.id, params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      this.data = result.map((i, idx) => ({ ...i, id: i.id || (idx + '') }));

      const columns = (data?.meta?.columns || []);
      columns.push({
        width: '120px',
        align: 'center',
        dataIndex: 'action',
        key: 'action',
        title: '操作',
        scopedSlots: { customRender: 'action' },
      });
      this.columns = columns;
      this.columns = [...columns.map((i) => {
        const item = { ...i, align: 'center' };

        if (['regional_org_name', 'org_title'].indexOf(i.key) > -1) {
          item.ellipsis = true;
          item.customCell = (record) => {
            return {
              style: {
                width: i.width || '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record[i.key], i.title || '详情'),
              },
            };
          };
        }

        return item;
      })];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    async exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = {
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        org_ids: this.orgIds,
        down: 1,
      };

      const data = await getTrainingScoreListDownload(this.id, params).finally(() => this.downloading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `导出失败`);
        return;
      }
      this.$message.success(data?.message || '导出成功');
    },

    viewRules() {
      this.$router.push({
        path: '/index/training/scoreRule/unified/add',
        query: {
          back: 1,
        },
      });
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content: content.split(','), title });
      }
    },

    viewMaterial(record) {
      this.$router.push({
        path: '/index/training/unified/detail/material',
        query: {
          id: this.id,
          o_id: record.org_id || '',
        },
      });
    },
    viewStudentDetail(record) {
      this.$router.push({
        path: '/index/training/unified/detail/student',
        query: {
          id: this.id,
          o_id: record.org_id || '',
        },
      });
    },

    evaluate(record) {
      if (this.$refs.gradeModalRef?.show) {
        this.$refs.gradeModalRef.show({
          title: '内训评价',
          trainingId: this.id,
          orgId: record.org_id,
          readonly: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
