import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/JsDownload';
import { baseUrl } from '@/Config';
import store from '@/store/index';
import { stringify } from 'qs';
// 表单管理---列表（统一+自主）
export function getFormShowList () {
  return request({
    url: `/internaltraining/admin/form/show`,
    method: 'get',
  });
}
// 表单管理---新增
export function postFromSave (data) {
  return request({
    url: `/internaltraining/admin/form/save`,
    method: 'post',
    data
  });
}
// 表单管理---修改
export function postFromSaveUpdate (id, data) {
  return request({
    url: `/internaltraining/admin/form/save/${id}`,
    method: 'post',
    data
  });
}
// 表单管理---启用
export function enableFromSaveUpdate (id) {
  return request({
    url: `/internaltraining/admin/form/enable/${id}`,
    method: 'post',
  });
}

// 表单管理---删除
export function deleteForm (id) {
  return request({
    url: `/internaltraining/admin/form/${id}`,
    method: 'delete',
  });
}


// 表单管理详细
export function getFormDetail (id) {
  return request({
    url: `/internaltraining/admin/form/detail/${id}`,
    method: 'get',
  });
}

// 表单管理详细
export function getExportFormByFrom (params, fileName) {
  let url = `/internaltraining/admin/form/exportFormByFrom`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
    fileName
  });
}


