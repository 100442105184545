import request from '@/utils/Request';


//评分规则管理 --获取列表
export function getConfigShow(params) {
    return request({
        url: `/internaltraining/admin/config/show`,
        method: 'get',
        params
    })
}


//评分规则管理 --保存评分规则
export function postConfigSave(data) {
    return request({
        url: `/internaltraining/admin/config/save`,
        method: 'post',
        data,
    })
}

//评分规则标签 --新增
export function postConfigAdd(data) {
    return request({
        url: `/internaltraining/admin/config/add`,
        method: 'post',
        data,
    })

}
//评分规则标签 --删除
export function deleteConfig(id) {
    return request({
        url: `/internaltraining/admin/config/delete/${id}`,
        method: 'delete',
    })
}


