<template>
  <div class="full-size">
    <a-form-model layout="inline">
      <a-form-model-item label="体验中心名称">
        <select-org v-model="orgIds" :show-label="false" @change="orgChanged"/>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button @click="onReset">重置</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="exportExcel">导出</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="back">返回</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="flex table-wrapper">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">

        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="viewMaterial(record)">查看提交内容</a-button>
          <a-button type="link" @click="viewScore(record)">评分明细</a-button>
          <a-button type="link" @click="viewStudent(record)">学员详情</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
    <full-content-modal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  getUnifiedTrainingDetailMaterial,
  getUnifiedTrainingDetailMaterialDownload,
} from '@/api/training/Index';

import Common from '@/utils/Common';

import Pagination, { getPagination } from '@components/Pagination.vue';
import SelectOrg from '@/components/SelectOrg.vue';
import FullContentModal from '../../components/FullContentModal.vue';

export default {
  name: 'Material',
  components: {
    FullContentModal,
    Pagination,
    SelectOrg,
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      downloading: false,
      loading: false,
      id: 0,

      orgIds: '',

      columns: [],
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;
    this.orgIds = this.$store.state.Training.unifiedDetailOrgIds || '';

    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },
    onReset() {
      this.$store.commit('Training/updateUnifiedDetailOrgIds', '');
      this.orgIds = '';

      this.onSearch();
    },
    orgChanged() {
      this.$store.commit('Training/updateUnifiedDetailOrgIds', this.orgIds);
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize);

      const data = await getUnifiedTrainingDetailMaterial(this.id, params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      this.data = result.map((i, idx) => ({ ...i, id: i.id || (idx + '') }));

      const columns = (data?.meta?.columns || []);
      columns.push({
        width: '120px',
        align: 'center',
        dataIndex: 'action',
        key: 'action',
        title: '操作',
        scopedSlots: { customRender: 'action' },
      });
      this.columns = columns;
      this.columns = [...columns.map((i) => {
        const item = { ...i, align: 'center' };

        if (['regional_org_name', 'org_title'].indexOf(i.key) > -1) {
          item.ellipsis = true;
          item.customCell = (record) => {
            return {
              style: {
                width: i.width || '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record[i.key], i.title || '详情'),
              },
            };
          };
        }

        return item;
      })];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, down) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        org_ids: this.orgIds,
        page: page,
        per_page: pageSize,
      };
      if (down) {
        params.down = 1;
      }
      return params;
    },
    async exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = this.getParams(this.pagination.current, this.pagination.pageSize, 1);
      const data = await getUnifiedTrainingDetailMaterialDownload(this.id, params).finally(() => this.downloading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `导出失败`);
        return;
      }
      this.$message.success(data?.message || '导出成功');
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content: content.split(','), title });
      }
    },

    viewMaterial(record) {
      this.$router.push({
        path: '/index/training/unified/detail/material',
        query: {
          id: this.id,
          o_id: record.org_id || '',
        },
      });
    },
    viewScore(record) {
      this.$router.push({
        path: '/index/training/unified/detail/score',
        query: { id: this.id, o_id: record.org_id },
      });
    },
    viewStudent(record) {
      this.$router.push({
        path: '/index/training/unified/detail/student',
        query: {
          id: this.id,
          o_id: record.org_id || '',
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  padding-top: 15px;
}
</style>
