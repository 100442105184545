<template>
  <div class="full-size auto-y">
    <CommonTitle :titles="titles"/>

    <div class="pd-card">
      <a-form-model layout="inline">
        <a-form-model-item label="查询时间范围">
          <a-range-picker :value="form.date" :mode="['month', 'month']" format="YYYY-MM"
                          @change="dateChange" @panelChange="dateChange"></a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="参与组织机构">
          <select-org v-model="form.org_ids"
                      :show-label="false"></select-org>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary"
                    class="mr-60"
                    @click="onSearch()">查询
          </a-button>
          <a-button class="mr-30"
                    @click="onReset">重置
          </a-button>
          <a-button @click="back">返回</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="flex flex-end pd-card">
      <a-button type="primary"
                :loading="downloading"
                :disabled="!data.length > 0"
                @click="exportExcel()">导出
      </a-button>
    </div>

    <div class="flex pd-card">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id"
               class="table small-cell-table">

        <template slot="action"
                  slot-scope="text, record">
          <a-button type="link"
                    @click="viewDetail(record)">查询明细
          </a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
    <full-content-modal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import moment from 'moment';
import {
  getScoreOrgList,
  getScoreOrgListDownload,
} from '@/api/training/Index';
import Common from '@/utils/Common';

import SelectOrg from '@/components/SelectOrg.vue';
import CommonTitle from '@components/CommonTitle.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';
import FullContentModal from '../components/FullContentModal.vue';


export default {
  name: 'ScoreOrgList',
  components: {
    CommonTitle,
    Pagination,
    SelectOrg,
    FullContentModal,
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '评分查询' }],

      downloading: false,
      loading: false,
      columns: [
        {
          width: '100px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
          customCell: (record) => {
            return {
              style: {
                width: '100px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record['regional_org_name'], '大区详情'),
              },
            };
          },
        },
        {
          width: '120px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'org_name',
          key: 'org_name',
          title: '体验中心名称',
          customCell: (record) => {
            return {
              style: {
                width: '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record['org_name'], '体验中心名称详情'),
              },
            };
          },
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'score',
          key: 'score',
          title: '总评分',
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      pagination: getPagination(),

      form: {
        org_ids: '',
        date: [moment().startOf('months'), moment().endOf('months')],
      },
    };
  },
  created() {
    this.form.org_ids = this.$route.query.o_id || this.form.org_ids || '';

    if (this.$store.state.Training.scoreMonth?.length > 1) {
      this.form.date = this.$store.state.Training.scoreMonth.map((i) => moment(i, 'YYYY-MM'));
    }

    this.onSearch();
  },
  methods: {
    dateChange(value) {
      this.form.date = value;
    },
    back() {
      this.$router.back();
    },
    onReset() {
      this.form = {
        org_ids: '',
        date: [moment().startOf('months'), moment()],
      };

      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      const params = this.getParams(page, pageSize);

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getScoreOrgList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = (data?.data || []).map((i, idx) => ({
        ...i,
        id: i.id || idx,
      }));

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, down) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        org_ids: this.form.org_ids,
        start_month: this.form.date[0]?.format('YYYY-MM') || '',
        end_month: this.form.date[1]?.format('YYYY-MM') || '',
        page: page,
        per_page: pageSize,
      };
      if (down) {
        params.down = 1;
      }

      return params;
    },
    async exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = this.getParams(this.pagination.current, this.pagination.pageSize, 1);
      const data = await getScoreOrgListDownload(this.id, params).finally(() => this.downloading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `导出失败`);
        return;
      }
      this.$message.success(data?.message || '导出成功');
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content: content.split(','), title });
      }
    },

    viewDetail(record) {
      if (this.form.date?.length > 1) {
        this.$store.commit('Training/updateScoreMonth', this.form.date.map((i) => i.format('YYYY-MM')));
      }

      this.$router.push({
        path: `/index/training/score/training`,
        query: {
          o_id: record.org_id,
          s_month: this.form.date[0].format('YYYY-MM'),
          e_month: this.form.date[1].format('YYYY-MM'),
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
