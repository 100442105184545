<template>
  <div class="uni-label">
    <h3>
      评价标签
      <div class="sr-tip">在内训详情中为经销商上选择评价标签</div>
    </h3>
    <div class="sr-top">
      <div class="sr-list-item" v-for="(item, index) in labelList" :key="index">
        <span class="sr-item-score sr-score-right">{{ item.title }}</span>
        <span class="sr-item-score sr-score-left">
          <a-input-group compact>
            <a-select
              :default-value="item.operator"
              disabled
              class="show-disabled"
            >
              <a-select-option :value="item.operator">
                {{ item.operator_str }}
              </a-select-option>
            </a-select>
            <a-input-number
              style="width: 50%"
              :disabled="true"
              v-model="item.value"
              default-value=""
              :class="item.operator === 1 ? '' : 'show-disabled'"
              :min="0"
              :max="100"
            />
            <span>
              <a @click="deleteConfigData(item)" class="delete-btn"
                >删除</a
              ></span
            >
          </a-input-group>
        </span>
      </div>
    </div>
    <div class="right-btn">
      <a-button type="primary" @click="showModel">添加评价标签</a-button>
    </div>
    <div class="score-tip">添加否决标签则此经销商本次内训的成绩为0</div>
    <!-- <div class="sb-item-btn">
      <a-button type="primary" @click="save"> 提交 </a-button>
    </div> -->
    <a-modal
      v-model="mode_visible"
      title="添加评价标签"
      @ok="handleOk"
      :ok-button-props="{ props: { disabled: isDisabled() } }"
    >
      <div class="sr-list-item">
        <span class="sr-item-score sr-score-right">标签名称:</span>
        <span class="sr-item-score sr-score-left">
          <a-input
            style="width: 50%"
            v-model="labelShowModel.title"
            default-value=""
          />
        </span>
      </div>
      <div class="sr-list-item">
        <span class="sr-item-score sr-score-right">标签动作:</span>
        <span class="sr-item-score sr-score-left">
          <a-input-group>
            <a-select
              :default-value="lableState[0].id"
              v-model="labelShowModel.operator"
            >
              <a-select-option
                :key="item.id"
                v-for="item in lableState"
                :value="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-input-group>
        </span>
      </div>
      <div class="sr-list-item" v-if="labelShowModel.operator != 1">
        <span class="sr-item-score sr-score-right">分数:</span>
        <span class="sr-item-score sr-score-left">
          <a-input-number
            class="item-content-input"
            :min="0"
            :max="100"
            width="100px"
            placeholder="请输入正整数"
            v-model="labelShowModel.value"
            @change="onChage"
          />
        </span>
      </div>
    </a-modal>
    <div class="loading">
      <Loading v-show="isLoading"></Loading>
    </div>
  </div>
</template>
<script>
import Loading from '../components/Loading.vue';
import {
  postConfigSave,
  getConfigShow,
  deleteConfig,
  postConfigAdd
} from '@/api/training/ScoreRule.js';

let labelList = [];

let model = {
  putInTime: '',
  putOutTime: '',
  ScoreFeedback: '',
  ShareCourseware: '',
  usingTheExamBySubject: '',
  usingTheCoursewareBySubject: '',
  minOfflineParticipationRate: '',
  passExamByOnce: '',
  unifiedMinPassRate: '',
  onlineFinishingRate: '',
  studyByCourseTask: '',
  joinTestPaperByPlatform: ''
};

let lableState = [
  {
    id: 0,
    value: '不详'
  }
];

let labelShowModel = {
  title: '',
  operator: 1,
  value: 1
};

export default {
  data() {
    return {
      model,
      mode_visible: false,
      lableState,
      labelShowModel,
      labelList,
      isLoading: false
    };
  },
  components: {
    Loading
  },
  methods: {
    getData() {
      this.isLoading = true;
      getConfigShow({ type: 1 })
        .then((res) => {
          if (res.error_code === 0) {
            this.lableState = res.meta.operator;
            this.labelList = res.data.label;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteConfigData(item) {
      this.$confirm({
        content: () => <div>确认删除该行？</div>,
        onOk: () => {
          this.isLoading = true;
          deleteConfig(item.id)
            .then((res) => {
              if (res.error_code >= 0) {
                this.$message.success('删除成功!');
                this.getData();
              } else {
                this.$message.error('删除失败!请稍后重试');
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    postConfigAdd() {
      if (this.labelShowModel.operator === 1) {
        this.labelShowModel.value = '';
      } else if (this.labelShowModel.value <= 0) {
        this.$message.error('标签分数不能输入0或非正整数');
        return;
      }
      this.isLoading = true;

      postConfigAdd(this.labelShowModel)
        .then((res) => {
          if (res.error_code >= 0) {
            this.$message.success('添加成功!');
            this.getData();
            this.mode_visible = false;
          } else {
            this.$message.error('添加失败!');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleOk() {
      this.postConfigAdd();
    },
    showModel() {
      this.mode_visible = true;
      this.labelShowModel = {
        title: '',
        operator: 1,
        value: ''
      };
    },
    save() {
      this.isLoading = true;
      postConfigSave(this.model)
        .then((res) => {
          if (res.error_code >= 0) {
            this.getData();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    isInteger(value) {
      return (value * 10) % 10 === 0;
    },
    isDisabled() {
      let result =
        (this.labelShowModel.operator !== 1 &&
          (this.labelShowModel.value <= 0 ||
            !this.isInteger(this.labelShowModel.value))) ||
        this.labelShowModel.title === '' ||
        (this.labelShowModel.operator === 1 &&
          this.labelShowModel.title === '');
      console.log(result);
      return result;
    },
    onChage(value) {
      if (!this.isInteger(value)) {
        this.$message.error('请输入正整数');
      }
    }
  },
  created: function () {
    this.getData();
  }
};
</script>

<style scoped lang="scss">
.uni-label {
  width: 100%;
  float: left;
  position: relative;
  padding: 5px 20px;
}
.sr-left {
  flex: 1;
}
.sr-right {
  flex: 1;
  border-left: 1px solid #000;
  position: relative;
}
.sr-top {
  margin-top: 30px;
}
.sr-list-item {
  display: flex;
  margin: 5px 0;
  width: 100%;
  min-width: 35px;
  max-height: 70px;
  line-height: 35px;
}
.list-item-title {
  width: auto;
  margin: 20px;
}

.sr-tip {
  display: block;
  color: #7f7f7f;
  font-size: 12px;
  margin: 10px 0 !important;
}
.item-content-input {
  width: 50%;
  margin: 5px;
}
.sr-item-title {
  line-height: 43px;
}
.sr-item-content {
  flex: 8;
  margin-left: 20px;
}
.sr-item-score {
  flex: 1;
  text-align: center;
}
.sr-score-left {
  text-align: left;
  margin-left: 40px;
}
.sr-score-right {
  text-align: right;
  max-width: 150px;
}
.score-tip {
  font-size: 16px;
  color: #000;
  left: 10px;
  margin: 20px 10px;
}
.right-btn {
  position: absolute;
  right: 20px;
  top: -50px;
}
.sb-item-btn {
  text-align: center;
}
.delete-btn {
  line-height: 32px;
  margin: 0 20px;
}
.show-disabled {
  color: #000;
}
</style>
