<template>
  <div class="scorerule-unified">
    <div class="scorerule-title">
    <h2>以下配置项仅对统一内训与既定主题的自主内训生效</h2>
    <!-- <h3>加分项</h3> -->
    </div>

    <div class="scorerule">
      <TabDetailPage  :menus="menus"></TabDetailPage>
    </div>
  </div>
</template>

<script>
// import { postConfigSave } from "@/api/training/ScoreRule.js";
import TabDetailPage from "@components/TabDetailPage";
let label = [];

let model = {
  putInTime: "",
  putOutTime: "",
  ScoreFeedback: "",
  ShareCourseware: "",
  usingTheExamBySubject: "",
  usingTheCoursewareBySubject: "",
  minOfflineParticipationRate: "",
  passExamByOnce: "",
  unifiedMinPassRate: "",
  onlineFinishingRate: "",
  studyByCourseTask: "",
  joinTestPaperByPlatform: "",
  label: label,
};


let menus = [
  {
    id: "1",
    title: "加分项",
    path: "/index/training/scoreRule/unified/add",
  },
  {
    id: "2",
    title: "评价标签",
    path: "/index/training/scoreRule/unified/label",
  },
];

export default {
  name: "SelfUnified",
  data() {
    return {
      model,
      label,
      menus,
    };
  },
  components: {
    TabDetailPage,
  },
  methods: {
    // ConfigSave() {
    //   postConfigSave(this.model).then((res) => {
    //     console.log(res);
    //   });
    // },
  },
};
</script>

<style scoped lang="scss">
.scorerule-unified {
  width: 100%;
  float: left;
}
.scorerule-title{
  margin: 0 25px;
}
.sr-left {
  flex: 1;
}
.sr-right {
  flex: 1;
  border-left: 1px solid #000;
  padding-left: 20px;
  position: relative;
}
.sr-top {
  margin-top: 30px;
}
.sr-list-item {
  display: flex;
  margin: 5px 0;
  width: 100%;
  min-width: 35px;
  max-height: 70px;
  line-height: 35px;
}
.list-item-title {
  width: auto;
  margin: 20px;
}

.sr-tip {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 0;
  margin: 10px 0;
}
.item-content-input {
  width: 80px;
  max-width: 80px;
  margin: 5px;
}
.sr-item-title {
  line-height: 43px;
}
.sr-item-content {
  flex: 8;
  margin-left: 20px;
}
.sr-item-score {
  flex: 1;
  text-align: center;
}
.sr-score-left {
  text-align: left;
  margin-left: 40px;
}
.sr-score-right {
  text-align: right;
  max-width: 150px;
}
.score-tip {
  position: absolute;
  font-size: 800;
  font-size: 16px;
  color: #000;
  left: 10px;
  bottom: 10px;
}
.right-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
