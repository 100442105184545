<template>
  <div class="full-size auto-y">
    <CommonTitle :titles="titles" />
    <div class="student-header">
      <Header :id.sync="this.id"></Header>
    </div>
    <div class="pd-card">
      <a-form-model layout="inline">
        <a-form-model-item label="学员姓名">
          <a-input v-model="form.name"
                   placeholder="请输入关键字"></a-input>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary"
                    class="mr-60"
                    @click="onSearch()">查询</a-button>
          <a-button @click="onReset">重置</a-button>
          <a-button @click="back">返回</a-button>
        </a-form-model-item>
        <a-form-model-item class="right">
          <a-button type="primary"
                    @click="exportExcel">导出</a-button>
        </a-form-model-item>
      </a-form-model>

    </div>

    <div class="flex pd-card">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               :rowKey="(record) => record.user_id"
               class="table small-cell-table">
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <full-content-modal ref="fullContentModalRef" />
  </div>
</template>

<script>
import { getSelfBuiltStudentList } from '@/api/training/SelfBuilt.js'

import { downloadFileExtra } from '@/utils/JsDownload.js'

import Common from '@/utils/Common'

import CommonTitle from '@components/CommonTitle'
import Pagination, { getPagination } from '@components/Pagination'

import FullContentModal from '../components/FullContentModal'

import Header from '../selfBuilt/components/Header.vue'

export default {
  name: 'Student',
  components: {
    CommonTitle,
    Pagination,
    FullContentModal,
    Header,
  },
  data() {
    return {
      titles: [
        { title: '内训管理' },
        { title: '自建内训管理' },
        { title: '学员明细' },
      ],

      downloading: false,
      loading: false,
      columns: [
        {
          width: '100px',
          align: 'center',
          dataIndex: 'name',
          key: 'name',
          title: '学员姓名',
        },
        {
          width: '180px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'postname',
          key: 'postname',
          title: '岗位',
          scopedSlots: { customRender: 'training_post_title' },
          customCell: (record) => {
            return {
              style: {
                width: '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () =>
                  this.showFullContent(
                    record['training_post_title'],
                    '参与岗位详情'
                  ),
              },
            }
          },
        },
      ],
      data: [],
      pagination: getPagination(),
      id: parseInt(this.$route.query.id),
      form: {
        name: '',
        org_id: '',
        id: '',
      },
    }
  },
  created() {
    this.onSearch()
  },
  methods: {
    onReset() {
      this.form = {
        name: '',
      }

      this.onSearch()
    },
    back() {
      this.$router.back()
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize)
    },
    onRefreshPage() {
      if (
        this.data?.length - 1 <= 0 &&
        this.pagination.current * this.pagination.pageSize >=
          this.pagination.total
      ) {
        this.getData(
          this.pagination.current > 1 ? this.pagination.current - 1 : 1,
          this.pagination.pageSize
        )
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize)
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize)
    },
    onSizeChange(current, size) {
      this.getData(1, size)
    },
    async getData(page, pageSize) {
      const params = this.getParams(page, pageSize)

      if (this.loading) {
        return
      }
      this.loading = true

      const data = await getSelfBuiltStudentList(params).finally(
        () => (this.loading = false)
      )
      if (!data || data.error_code) {
        return
      }
      let columns = []
      data.meta.columns.forEach((element) => {
        columns.push({
          ...element,
          align: 'center',
        })
      })
      this.columns = columns
      this.data = data?.data || []
      const pagination = data?.meta?.pagination || {}
      this.pagination = Common.updatePagination(this.pagination, pagination)
    },
    getParams(page, pageSize) {
      page = page || 1
      pageSize = pageSize || this.pagination.defaultPageSize

      const params = {
        page: page,
        per_page: pageSize,
        training_id: this.$route.query.id,
        org_id: this.$route.query.orgId,
        name: this.form.name,
      }
      delete params.date

      return params
    },
    exportExcel() {
      let url = '/internaltraining/admin/Student/TrainingStatistics'
      let paramStr = ''
      const params = this.getParams(
        this.pagination.current,
        this.pagination.pageSize
      )
      for (let key in params) {
        paramStr = `${paramStr}&${key}=${params[key]}`
      }
      this.$message.info('正在下载...')
      downloadFileExtra({
        url: `${url}?${paramStr}&down=1`,
        token: this.$store.state.User.token,
      }).then(
        () => {
          this.$message.success('下载成功')
        },
        () => {
          this.$message.error('下载失败')
        }
      )
    },
    showFullContent(content, title) {
      if (!content) {
        return
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content, title })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.student-header {
  padding: 20px;
}
.right {
  float: right;
}
</style>
