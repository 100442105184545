import request from '@/utils/Request';

//获取公开云列表
export function getCloudList(params) {
    return request({
        url: `/internaltraining/admin/cloud`,
        method: 'get',
        params,
    })
}
// //获取文件类型
// export function getCloudTypeList(params) {
//     return request({
//         url: `/api/system/knowledge/files/types`,
//         method: 'get',
//         params,
//     })
// }


//公开云下架
export function postShutoff(id) {
    return request({
        url: `/internaltraining/admin/cloud/shutoff/${id}`,
        method: 'post',
    })
}

//公开云删除
export function deleteShutoff(id) {
    return request({
        url: `/internaltraining/admin/cloud/delete/${id}`,
        method: 'delete',
    })
}

//公开云打分
export function postScoreCloud(data) {
    return request({
        url: `/internaltraining/admin/cloud/scoreCloud`,
        method: 'post',
        data
    })
}

//公开云内容审核
export function getCloudAudiList(params){
    return request({
        url: `/internaltraining/admin/cloud/auditlist`,
        method: 'get',
        params
    })
}

//公开云内容审核
export function postCloudPass(data){
    return request({
        url: `/internaltraining/admin/cloud/pass`,
        method: 'post',
        data
    })
}

//公开云内容审核
export function postCloudNoPass(data){
    return request({
        url: `/internaltraining/admin/cloud/nopass`,
        method: 'post',
        data
    })
}



