<template>
  <div class="full-size">
    <CommonTitle :titles="titles">
      <div slot="buttons">
        <a-button type="primary" @click="addSurvey">添加调研</a-button>
      </div>
    </CommonTitle>

    <div class="flex flex-start ph-20">
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="isLoading"
        :pagination="false"
        bordered
        rowKey="id"
        class="table small-cell-table">
        <a slot="url" slot-scope="text" :href="text" target="_blank">{{ text }}</a>

        <span slot="is_switch" slot-scope="text, item">
          <span v-if="item.is_switch"> 已上架 </span>
          <span v-else> 已下架 </span>
        </span>
        <template slot="active" slot-scope="text, item">
          <template v-if="!item.is_switch">
            <a-button type="link" @click="hrefDetail(item.id)">编辑</a-button>
            <a-button type="link" @click="setIsSwitch(item)">上架</a-button>
            <a-button type="link" @click="deleteItem(item)">删除</a-button>
          </template>
          <template v-else>
            <a-button type="link" @click="hrefDetail(item.id)">查看</a-button>
            <a-button type="link" @click="setIsSwitch(item)">下架</a-button>
          </template>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getResearchList, postResearchShuton, deleteResearch } from '@/api/training/Survey';

import CommonTitle from '@components/CommonTitle';
import Pagination, { getPagination } from '@components/Pagination.vue';

const titles = [{ title: '内训管理' }, { title: '调研管理' }];

const columns = [
  {
    title: '标题',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '链接',
    align: 'center',
    dataIndex: 'url',
    key: 'url',
    width: 500,
    scopedSlots: { customRender: 'url' },
  },
  {
    title: '状态',
    align: 'center',
    dataIndex: 'is_switch',
    key: 'is_switch',
    scopedSlots: { customRender: 'is_switch' },
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'active',
    scopedSlots: { customRender: 'active' },
  },
];

export default {
  name: 'List',
  data() {
    return {
      titles,
      columns,
      data: [],
      pagination: getPagination(),
      isLoading: false,
    };
  },
  components: {
    CommonTitle,
    Pagination,
  },
  methods: {
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getList(page, pageSize) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const data = await getResearchList({
        page,
        per_page: pageSize,
      }).finally(() => this.isLoading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];
    },
    // 上下架
    async setIsSwitch(item) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const data = await postResearchShuton(item.id).finally(() => this.isLoading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `${item.is_switch ? '下架' : '上架'}失败`);
        return;
      }
      this.$message.success(data?.message || `${item.is_switch ? '下架' : '上架'}成功`);
      this.getList();
    },

    addSurvey() {
      this.hrefDetail();
    },

    hrefDetail(id) {
      const params = {
        path: 'detail',
      };
      if (id) {
        params.query = { id };
      }
      this.$router.push(params);
    },

    deleteItem(item) {
      this.$confirm({
        title: '提示',
        content: '确认删除该调研？',
        onOk: () => this.confirmDeleteItem(item),
      });
    },
    async confirmDeleteItem(item) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const data = await deleteResearch(item.id).finally(() => this.isLoading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.getList(this.pagination.current, this.pagination.pageSize);
    },
  },
  created() {
    this.getList(1, this.pagination.pageSize);
  },
};
</script>

<style scoped lang="scss">
</style>
