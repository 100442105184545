<template>
  <a-select v-model="selectedIds"
            :mode="mode"
            :allow-clear="true"
            :show-search="true"
            :filter-option="false"
            :disabled="disabled"
            placeholder="请搜索课程"
            class="select"
            @focus="resetSearch"
            @blur="resetSearch"
            @popupScroll="onScroll"
            @change="select"
            @search="onSearch">
    <a-select-option v-for="(item) in filteredOptions" :key="item.id" :value="item.id">
      {{ item.title }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectCourse',
  model: {
    prop: 'idStr',
    event: 'change',
  },
  props: {
    idStr: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    mode: {
      type: String,
      default: 'multiple',
      validator: (val) => ['multiple', 'default'].includes(val),
    },
  },
  computed: {
    ...mapState({
      options: (state) => state.Common.courseList,
    }),
  },
  watch: {
    idStr() {
      this.setDefault();
      this.onSearch('');
    },
    options() {
      this.onSearch(this.filterTxt);
    },
  },
  data() {
    return {
      selectedIds: this.mode === 'default' ? undefined : [],
      isAllClicked: false,

      page: 1,
      perPage: 50,
      filterTxt: '',
      filteredOptions: [],
    };
  },
  created() {
    if (!this.options?.length) {
      this.$store.dispatch('Common/getCourses').finally(() => {
        this.setDefault();
        this.onSearch('');
      });
    } else {
      this.setDefault();
      this.onSearch('');
    }
  },
  methods: {
    resetSearch() {
      this.onSearch('');
    },
    onSearch(value) {
      this.filterTxt = value;
      this.page = 1;
      this.getFilteredOptions();
    },

    getFilteredOptions() {
      this.filteredOptions = this.options.filter((option, index) => {
        if (this.mode === 'default' && +option.id === +this.selectedIds) {
          return true;
        } else if (this.mode === 'multiple' && this.selectedIds.map(i => +i).indexOf(+option.id) > -1) {
          return true;
        }
        if (!this.filterTxt && index < this.page * this.perPage) {
          return true;
        }
        return this.filterTxt && option.title.indexOf(this.filterTxt) > -1;
      });
    },

    onScroll(ev) {
      const target = ev.currentTarget || ev.target || ev.srcElement;
      if (!target) {
        return;
      }

      const height = target.clientHeight || 250;
      const scrollTop = target.scrollTop || 0;
      const scrollHeight = target.scrollHeight || 250;

      if (scrollTop + 1.2 * height > scrollHeight && this.page * this.perPage < this.options.length) {
        this.loadMore();
      }
    },

    loadMore() {
      this.page += 1;
      this.getFilteredOptions();
    },

    selectAll() {
      this.isAllClicked = true;
    },

    setDefault() {
      if (!this.idStr) {
        this.ids = '';
        this.selectedIds = this.mode === 'default' ? undefined : [];
        return;
      }
      const ids = this.idStr.split(',');
      this.selectedIds = this.mode === 'default' ? ids.map((i) => +i)[0] : ids.map((i) => +i);
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      if (this.mode === 'multiple' && this.isAllClicked) {
        this.selectedIds = this.options.map((op) => op.id);
        this.selectedIds.unshift(0);
      }

      this.isAllClicked = false;

      const ids = this.mode === 'default' ? [this.selectedIds] : this.selectedIds.filter((i) => !!i);
      const items = this.options.filter((op) => ids.includes(op.id));
      this.$emit('select', ids, items);
      this.$emit('change', ids.join(','));
    },
  },
};
</script>

<style scoped lang="less">
.select {
  min-width: 350px;

  ::v-deep .ant-select-selection {
    transform: translateY(5px);
    overflow-y: auto;
    max-height: 120px;
  }
}
</style>
