<template>
  <a-modal :title="title"
           :visible="visible"
           :confirm-loading="confirmLoading"
           :mask-closable="false"
           :width="800"
           :centered="true"
           :destroy-on-close="true"
           :afterClose="afterClose"
           @ok="confirm"
           @cancel="close">
    <a-row v-for="(group) in scores"
           :key="group.id"
           class="flex flex-start align-start full-width mb-20 group">
      <a-col class="flex-none flex flex-end group-title">{{ group.title }}</a-col>
      <a-col class="flex items">
        <template v-if="group.list && group.list.length">
          <div v-for="item in group.list"
               :key="item.id"
               :value="item.id"
               class="flex item"
               :class="{'active': selectedIds.includes(item.id), 'readonly': readonly}"
               @click="selectItem(item)">
            <span>{{ item.title }}</span>
            <span v-if="group.id !== 1"
                  class="score">
              {{ group.id === 2 ? '+' : '' }}{{ group.id === 3 ? '-' : '' }}{{ item.value }}
            </span>
          </div>
        </template>
      </a-col>
    </a-row>

    <a-row class="flex flex-start align-start full-width mb-20 group">
      <a-col class="flex-none flex flex-end group-title"></a-col>
      <a-col class="flex sum">
        <p v-if="isClear"
           class="clear">本次内训评分清零</p>
        <p v-else>分数小结: {{ totalScore }}</p>
      </a-col>
    </a-row>

    <div slot="footer"
         class="flex flex-end">
      <a-button @click="close">取消</a-button>
      <a-button v-if="!readonly"
                type="primary"
                @click="confirm">确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import {
  getTrainingScores,
  saveTrainingScore,
} from '@/api/training/Index';

export default {
  name: 'GradeModal',
  computed: {
    isClear() {
      // id === 1 为否决项
      const clearScore = this.scores.find((i) => i.id === 1) || {};
      return (clearScore?.list || []).findIndex((i) => this.selectedIds.includes(i.id)) > -1;
    },
    totalScore() {
      let sum = 0;
      if (!this.scores?.length) {
        return sum;
      }
      this.scores.forEach((score) => {
        if (score.id === 1 || !score?.list?.length) {
          return;
        }
        score.list.forEach((i) => {
          if (this.selectedIds.includes(i.id)) {
            if (score.id === 2) {
              sum += (+i.value);
            }
            if (score.id === 3) {
              sum -= (+i.value);
            }
          }
        });
      });

      return sum;
    },
  },
  data() {
    return {
      confirmLoading: false,
      visible: false,

      selectedIds: [],
      scores: [],

      title: '',
      trainingId: 0,
      orgId: 0,
      readonly: false,
    };
  },
  methods: {
    show({ title, trainingId, orgId, readonly } = {}) {
      this.title = title;
      this.trainingId = trainingId;
      this.orgId = orgId;
      this.readonly = !!readonly;

      this.getTrainingScoreList();

      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.title = '';
      this.trainingId = 0;
      this.orgId = 0;
      this.selectedIds = [];

      this.scores = [];
    },

    async confirm() {
      if (this.confirmLoading) {
        return;
      }
      this.confirmLoading = true;

      const data = await saveTrainingScore(this.trainingId, {
        org_id: this.orgId,
        config_ids: (this.selectedIds || []).filter((i) => !!i).join(','),
      }).finally(() => this.confirmLoading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data?.message || '保存成功');
      this.$emit('changed');
      this.close();
    },

    async getTrainingScoreList() {
      const data = await getTrainingScores(this.trainingId, {
        org_id: this.orgId,
      });
      if (!data || data.error_code) {
        return;
      }
      this.selectedIds = (data?.data?.config_ids || []).filter((i) => !!i).map((i) => +i);

      const settledItems = data?.data?.manager_config_arr || [];
      const scores = data?.meta?.manager_score || [];

      if (data?.data?.is_settlement === 2 && settledItems?.length) {
        settledItems.forEach((i) => {
          const idx = scores.findIndex((score) => score.id === i.operator);
          if (idx > -1) {
            const list = scores[idx]?.list;
            if (list?.length) {
              if (list.every((item) => item.id !== i.id)) {
                list.push(i);
              }
            }
          }
        });
      }

      this.scores = scores || [];
    },

    selectItem(item) {
      if (this.readonly) {
        return;
      }
      const idx = this.selectedIds.indexOf(item.id);

      if (idx === -1) {
        this.selectedIds.push(item.id);
      } else {
        this.selectedIds.splice(idx, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.group-title {
  width: 120px;
  height: 38px;
  padding-right: 20px;
  font-weight: 700;
}

.group:last-child {
  margin-bottom: 0;
}

.items {
  min-height: 38px;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.item {
  height: 28px;
  margin: 5px;
  padding: 0 12px;
  border: 1px solid #535353;
  border-radius: 14px;
  color: #333333;
  cursor: pointer;

  &.active {
    border-color: #e60212;
    color: #ffffff;
    background-color: #e60212;

    .score {
      border-left-color: #d6d6d6;
    }
  }

  &.readonly {
    cursor: not-allowed;
  }

  .score {
    height: 1.2em;
    line-height: 1.2em;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #535353;
  }
}

.sum {
  flex: auto;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
}

.clear {
  color: #e60212;
}
</style>
