<template>
  <div class="full-size auto-y ph-20">
    <CommonTitle :titles="titles"/>

    <div v-if="!loading && (!data || !data.length)" class="ant-empty" style="padding: 25px 0;">没有评分数据</div>

    <div v-for="(item, idx) in data" :key="idx" class="flex flex-start">
      <div class="flex-none row-title">{{ item.key }}：</div>
      <div class="row-content">{{ item.value }} 分</div>
    </div>

    <div class="flex flex-start total-row">
      <div class="flex-none row-title">总分：</div>
      <div class="row-content">{{ total }} 分</div>
    </div>

    <div class="flex flex-start back-wrapper">
      <a-button @click="back">返回</a-button>
    </div>
  </div>
</template>

<script>
import {
  getScoreDetail,
  getScoreDetailDownload,
} from '@/api/training/Index';

import CommonTitle from '@components/CommonTitle.vue';

export default {
  name: 'ScoreDetail',
  components: {
    CommonTitle,
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '评分查询' }],

      downloading: false,
      loading: false,
      id: undefined,
      data: [],
      total: 0,
    };
  },
  created() {
    this.id = this.$route.query.id || undefined;
    this.orgIds = this.$route.query.o_id || '';

    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },
    onSearch() {
      this.getData();
    },
    async getData() {
      const params = this.getParams();

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getScoreDetail(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];
      this.total = data?.meta?.total_score || 0;
    },
    getParams(down) {
      const params = {
        training_id: this.id,
        org_ids: this.orgIds,
      };
      if (down) {
        params.down = 1;
      }

      return params;
    },
    async exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = this.getParams(1);
      const data = await getScoreDetailDownload(params).finally(() => this.downloading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `导出失败`);
        return;
      }
      this.$message.success(data?.message || '导出成功');
    },
  },
};
</script>

<style scoped lang="scss">
.total-row {
  margin-top: 20px;
  border-top: 1px solid #7C7C7C;
}

.row-title {
  width: 280px;
  height: 38px;
  line-height: 38px;
  margin-right: 10px;
  text-align: right;
}

.row-content {
  width: 120px;
  text-align: right;
}

.back-wrapper {
  margin-top: 30px;
  padding-left: 200px;
}
</style>

