<template>
  <a-checkbox-group
    class="select-job-grouped"
    :value="idList"
    :disabled="disabled"
    @change="changed">
    <a-row
      v-for="group in ops"
      :key="group.id"
      class="flex flex-start align-start full-width mb-20 group">
      <a-col class="flex-none flex flex-end group-title">【{{ group.group_name }}】</a-col>
      <a-col class="items">
        <template v-if="group.post_arr && group.post_arr.length">
          <a-checkbox
            v-for="item in group.post_arr"
            :key="item.id"
            :value="item.id"
            :disabled="disabledIds.split(',').includes(item.id+'')"
            class="mb-5"
            style="margin-left: 5px; margin-right: 5px">
            {{ item.name }}
          </a-checkbox>
        </template>
      </a-col>
    </a-row>
  </a-checkbox-group>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectJobGrouped',
  model: {
    prop: 'ids',
    event: 'change',
  },
  props: {
    ids: { type: String, default: '' },
    disabledIds: { type: String, default: '' },
    list: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      options: (state) => state.Common.jobListGrouped,
    }),
    ops() {
      return this.list?.length ? this.list : this.options;
    },
  },
  watch: {
    ids: {
      immediate: true,
      handler(ids) {
        this.idList = (ids || '').split(',').map((i) => +i).filter((i) => !!i);
      },
    },
  },
  data() {
    return {
      idList: [],
    };
  },
  created() {
    if (!this.options?.length) {
      this.$store.dispatch('Common/getJobsGrouped');
    }
  },
  methods: {
    changed(ids) {
      this.$emit('change', (ids || []).join(','));
      this.$emit('changed', (ids || []).join(','));
    },
  },
};
</script>

<style scoped lang="scss">
.group-title {
  width: 120px;
}

.group:last-child {
  margin-bottom: 0;
}
</style>
