<template>
  <a-select v-model="selectedId"
            :disabled="disabled"
            :placeholder="placeholder"
            class="select"
            @change="select">
    <a-select-option v-for="(item) in options"
                     :key="item.id"
                     :value="item.id">
      {{ item.title }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'SelectMaterialType',
  model: {
    prop: 'ids',
    event: 'change',
  },
  props: {
    ids: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
  },
  watch: {
    ids: {
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
  },
  data() {
    return {
      selectedId: undefined,
    };
  },
  methods: {
    setDefault() {
      this.selectedId = +this.ids || undefined;
    },
    select() {
      this.$emit('change', this.selectedId + '');
      const [item] = this.options.filter((o) => o.id === this.selectedId) || [
        {},
      ];
      this.$emit('select', item);
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 150px;

  ::v-deep .ant-select-selection {
    transform: translateY(5px);
    overflow-y: auto;
    max-height: 120px;
  }
}
</style>
