span<template>
  <div class="form-detail">
    <div class="postconfig-verfy">
      <CommonTitle :titles="titles"> </CommonTitle>
    </div>
    <div class="pd-content">
      <div class="pd-content-left">
        <h2>表单名称</h2>
      </div>
      <div class="pd-content-right">
        <div>
          <span>
            <a-input class="pd-content-input"
                     v-model="data.title"
                     :disabled="id > 0 ? true : false" />
          </span>
        </div>
      </div>
    </div>
    <div class="pd-divider">
      <hr />
    </div>
    <div class="pd-content"
         v-for="(item, index) in form"
         :key="index">
      <div class="pd-content-left">
        <h3>字段{{ index + 1 }}</h3>
      </div>
      <div class="pd-content-right">
        <span>
          <a-input-group compact>
            <a-select v-if="item.id > 0"
                      :default-value="item.type"
                      :disabled="item.id > 0 ? true : false"
                      class="min-select">
              <a-select-option :value="item.type"
                               class="min-select">
                {{ item.type_title }}
              </a-select-option>
            </a-select>
            <a-select v-if="item.id < 0"
                      v-model="item.type"
                      class="min-select">
              <a-select-option v-for="type_item in text_type"
                               :key="type_item.type"
                               class="min-select">
                {{ type_item.type_str }}
              </a-select-option>
            </a-select>
            <a-input class="pd-content-input"
                     v-model="item.value"
                     :disabled="item.id > 0 ? true : false" />
            <span>
              <a class="pd-content-a"
                 @click="delRows(item.id)"
                 :disabled="isdisabled"> 删除 </a></span>
          </a-input-group>
        </span>
      </div>
    </div>
    <div class="pd-content">
      <div class="pd-content-left">
        <a-button type="primary"
                  @click="addRows"
                  :disabled="isdisabled"> 添加字段 </a-button>
      </div>
      <div class="pd-content-right"></div>
    </div>

    <div class="pd-content-btn">
      <a-button type="primary"
                @click="submitRows"
                :disabled="isdisabled">
        提交
      </a-button>
      <a-button @click="back">返回</a-button>
    </div>
    <!--
    <a-modal
      title="提示"
      :visible="show_model.visible"
      :confirm-loading="show_model.confirmLoading"
      @ok="showModelOk"
      @cancel="showModelCancel"
    >
      <div v-html="show_model.model_text"></div>
    </a-modal> -->
    <div class="loading">
      <Loading v-show="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@components/CommonTitle";
import Loading from "../components/Loading.vue";
import {
  getFormDetail,
  // deleteForm,
  postFromSave,
  postFromSaveUpdate,
} from "@/api/training/Form.js";

let titles = [
  { title: "内训管理" },
  { title: "内训岗位权限管理" },
  { title: "配置岗位" },
];

let show_model = {
  visible: false,
  confirm_loading: false,
  model_text: "",
  id: 0,
  isOk: 0,
};
let text_type = [
  {
    type_str: "文本",
    type: "text",
  },
  {
    type_str: "时间控件",
    type: "dateplug",
  },
];

export default {
  name: "Verify",
  data () {
    return {
      titles,
      id: 0,
      data: {},
      num: -1,
      show_model,
      form: [],
      isdisabled: false,
      isLoading: false,
      text_type,
    };
  },
  components: {
    CommonTitle,
    Loading,
  },
  methods: {
    getList () {
      this.id = this.$route.query.id;
      if (this.id > 0) {
        this.isLoading = true;
        getFormDetail(this.id)
          .then((res) => {
            if (res.error_code === 0) {
              this.data = res.data;
              this.isdisabled = this.data.is_used === 1;
              if (res.data?.field_arr.length !== 0) {
                this.form = res.data?.field_arr;
              } else {
                this.addRows();
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.addRows();
      }
    },
    addRows () {
      this.num = --this.num;
      this.form.push({
        id: this.num,
        value: "",
        type: "text",
      });
    },
    delRows (id) {
      this.form = this.form.filter((s) => s.id !== id);
    },
    submitRows () {
      let msg = "";
      let form = this.form;
      if (
        this.data?.title?.trim() === undefined ||
        this.data?.title?.trim() === ""
      ) {
        msg = `表单名称不能为空`;
        this.$message.error(msg);
        return;
      }
      for (let i = 0; i < form.length; i++) {
        let item = form[i];
        if (item?.type === "") {
          msg = `字段${i + 1}字段类型不能为空`;
          this.$message.error(msg);
          return;
        }
        if (item?.value?.trim() === "") {
          msg = `字段${i + 1}不能为空`;
          this.$message.error(msg);
          return;
        }
        if (item.id < 0) {
          form[i] = { value: item.value, type: item.type };
        } else {
          form[i] = { value: item.value, type: item.type, id: item.id };
        }
      }

      if (msg !== "") {
        return;
      }
      let data = {
        fieldJson: JSON.stringify(form),
        form_name: this.data.title,
      };
      this.isLoading = true;
      if (this.id > 0) {
        postFromSaveUpdate(this.id, data)
          .then((res) => {
            if (res.error_code === 0) {
              this.$message.success("修改成功!");
           this.$router.back();
            } else {
              this.$message.error("修改失败!");
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        postFromSave(data)
          .then((res) => {
            if (res.error_code === 0) {
              this.$message.success("添加成功!");
              this.$router.push({
                path: "list",
              });
            } else {
              this.$message.error("添加失败!");
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    back () {
      this.$router.back();
    },
  },
  created () {
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.form-detail {
  position: relative;
}
.pd-content {
  margin-top: 30px;
  display: flex;
}
.pd-content-list {
  display: block;
  // width: 100%;
}
.pd-content-item {
  display: flex;
  // width: 100%;
}
.pd-content-left {
  flex: 1;
  min-width: 150px;
  text-align: right;
}
.pd-content-right {
  flex: 8;
  margin-left: 20px;
}
.pd-content-right div span {
  margin: 10px;
}
.pd-content-tip {
  margin-top: 30px;
  display: block;
  width: 100%;
}
.pd-content-btn {
  margin-top: 30px;
  display: block;
  // width: 100%;
  text-align: center;
}
.pd-content-input {
  width: 300px;
}
.pd-divider {
  margin-left: 100px;
}
.pd-content-a {
  height: 30px;
  line-height: 30px;
  margin: 0 20px;
}
.min-select {
  min-width: 100px;
  text-align: center;
}
</style>
