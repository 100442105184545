<template>
  <div class="full-size pos-relative">
    <block-loading v-if="loading"
                   tip=""/>
    <a-collapse v-model="activeKey">
      <a-collapse-panel key="1"
                        header="基本信息">
        <a-row>
          <a-col span="4"
                 :align="'right'"
                 class="title">内训主题
          </a-col>
          <a-col span="18">{{ basic.title }}</a-col>
        </a-row>
        <a-row>
          <a-col span="4"
                 :align="'right'"
                 class="title">开始时间
          </a-col>
          <a-col span="18">{{ basic.starttime_1 }}</a-col>
        </a-row>
        <a-row>
          <a-col span="4"
                 :align="'right'"
                 class="title">结束时间
          </a-col>
          <a-col span="18">{{ basic.endtime_1 }}</a-col>
        </a-row>
        <a-row>
          <a-col span="4"
                 :align="'right'"
                 class="title">组织机构
          </a-col>
          <a-col span="18">
            <div v-for="(item, index) in basic.push_org_org_arr"
                 :key="item.org_id"
                 style="display: inline-flex;">
              <span v-if="index">，</span>
              {{ item.org_title }}
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4"
                 :align="'right'"
                 class="title">主题合理性评价
          </a-col>
          <a-col span="18">{{ basic.is_subjective ? '已开启' : '未开启' }}</a-col>
        </a-row>
      </a-collapse-panel>
      <a-collapse-panel v-if="scienceData.length >0" key="5"
                        header="培训资料">
        <a-table :columns="columnsScience"
                 :data-source="scienceData"
                 :pagination="false"
                 bordered
                 row-key="id"
                 class="table small-cell-table">
          <template slot="url"
                    slot-scope="text, record">
            <span v-if="record.url === ''">--</span>
            <div v-else>
              <span v-if="!record.url.length || !record.url">无示例</span>
              <a
                v-if="record.type === 3 || record.type === 5 || (record.type === 6 && record.ext_str.indexOf('mp4') > -1)"
                :href="record.url"
                target="_blank">点击查看示例文件</a>
              <a-button v-else
                        type="link"
                        style="height: auto; margin: 0; padding: 0;"
                        @click="downloadItem(record)">点击查看示例文件
              </a-button>
            </div>
          </template>
          <template slot="num"
                    slot-scope="text, record">
            <span v-if="record.type === 3">--</span>
            <span v-else>{{ record.num }}</span>
          </template>
        </a-table>
      </a-collapse-panel>
      <a-collapse-panel key="2"
                        header="人数设置">
        <a-table :columns="postColumns"
                 :data-source="posts"
                 :pagination="false"
                 row-key="post_id"
                 class="table small-cell-table"
                 style="width: 800px;"></a-table>
      </a-collapse-panel>
      <a-collapse-panel v-if="+trainingType === 1"
                        key="3"
                        header="内训内容">
        <div v-if="!posts || !posts.length">未配置内训内容</div>

        <a-menu v-model="selectedPostId"
                mode="horizontal"
                class="mb-20"
                @select="selectPost">
          <a-menu-item v-for="(item) in posts"
                       :key="item.post_id">
            {{ item.post_title }}
          </a-menu-item>
        </a-menu>

        <div v-if="!courses || !courses.length">未配置课程</div>
        <div v-else
             class="flex flex-start content-title">请完成下列课程学习
        </div>
        <div v-for="(course) in courses"
             :key="'course-' + course.id"
             class="course">
          <div class="course-title">{{ course.detail_title }}</div>
          <ul v-if="course.online_task_list"
              class="course-task">
            <li v-for="(item) in course.online_task_list"
                :key="'course-task-' + item.id"
                class="course-task-item">{{ item.title }}
            </li>
          </ul>
        </div>

        <div v-if="!exams || !exams.length">未配置考试</div>
        <div v-else
             class="flex flex-start content-title">请参与下列统一考试
        </div>
        <div v-for="(exam) in exams"
             :key="'exam-' + exam.id"
             class="exam">
          <div class="exam-title">{{ exam.detail_title }}</div>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="4"
                        header="回传材料">
        <a-table :columns="columns"
                 :data-source="materialData"
                 :pagination="false"
                 bordered
                 row-key="id"
                 class="table small-cell-table">
          <!--          <template slot="material_title" slot-scope="text, record">-->
          <!--            <span v-if="record.type === 3">&#45;&#45;</span>-->
          <!--            <span v-else>{{ text }}</span>-->
          <!--          </template>-->
          <template slot="url"
                    slot-scope="text, record">
            <span v-if="record.type === 3">--</span>
            <div v-else>
              <span v-if="!record.example_resource_info.length || !record.example_resource_info[0]">无示例</span>
              <a v-else-if="record.example_resource_info[0].type === 3
                  || record.example_resource_info[0].type === 5
                  || (record.example_resource_info[0].type === 6 && record.ext_str.indexOf('mp4') > -1)"
                 :href="record.example_resource_info[0].url"
                 target="_blank">点击查看示例文件</a>
              <a-button v-else
                        type="link"
                        style="height: auto; margin: 0; padding: 0;"
                        @click="downloadItem({
                          url: record.example_resource_info[0].url,
                          type: record.example_resource_info[0].type,
                          file_name: record.title,
                          ext_str: record.ext_str
                        })">点击查看示例文件
              </a-button>
            </div>
          </template>
          <template slot="num"
                    slot-scope="text, record">
            <span v-if="record.type === 3">--</span>
            <span v-else>{{ record.num }}</span>
          </template>
        </a-table>
      </a-collapse-panel>
    </a-collapse>

    <div class="flex flex-start mt-30 mb-20">
      <a-button :disabled="!!basic.is_publish"
                type="primary"
                @click="publish">
        {{ basic.is_publish ? '已发布' : '发布' }}
      </a-button>
      <a-button @click="back(1)">返回列表</a-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  getUnifiedTrainingConfirm,
  publishUnifiedTraining,
  getMaterialShow,
} from '@/api/training/Index';
import { downloadFileExtra } from '@/utils/JsDownload';

import BlockLoading from '@/components/BlockLoading.vue';
import { menus as unifiedMenus } from '../unified/Detail';
import { menus as independentMenus } from '../independent/Detail';

const postColumns = [
  {
    width: '100px',
    align: 'center',
    dataIndex: 'post_title',
    key: 'post_title',
    title: '岗位名称',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'detail_number',
    key: 'detail_number',
    title: '最低参与人数',
  },
];
const columns = [
  {
    width: '100px',
    align: 'center',
    dataIndex: 'type_title',
    key: 'type_title',
    title: '资料类型',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '标题',
    // scopedSlots: { customRender: 'material_title' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'url',
    key: 'url',
    title: '示例',
    scopedSlots: { customRender: 'url' },
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'num',
    key: 'num',
    title: '数量',
    scopedSlots: { customRender: 'num' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'endtime_str',
    key: 'endtime_str',
    title: '最晚提交时间',
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'desc',
    key: 'desc',
    title: '描述',
  },
];

const columnsScience = [
  {
    width: '150px',
    align: 'center',
    dataIndex: 'file_name',
    key: 'file_name',
    title: '文件名称',
    scopedSlots: { customRender: 'file_name' },
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'type_str',
    key: 'type_str',
    title: '文件类型',
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'url',
    key: 'url',
    title: '示例',
    scopedSlots: { customRender: 'url' },
  },
];

export default {
  name: 'CreateTrainingConfirm',
  components: { BlockLoading },
  props: {
    trainingType: { type: String, default: '1' },
  },
  computed: {
    ...mapState({}),
    menus() {
      switch (+this.trainingType) {
        case 1:
          return unifiedMenus;
        case 2:
          return independentMenus;
        default:
          return [];
      }
    },
    currentPost() {
      if (!this.selectedPostId) {
        return {};
      }
      return this.postDetail[this.selectedPostId] || {};
    },
    courses() {
      return this.currentPost.online_course || [];
    },
    exams() {
      return this.currentPost.online_exam || [];
    },
  },
  data() {
    return {
      loading: false,
      id: 0,

      activeKey: ['1', '2', '3', '4', '5'],

      basic: {},

      posts: [],
      postColumns,
      postDetail: {},

      selectedPostId: undefined,

      materialData: [],
      columns,
      columnsScience,
      scienceData: [],
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;

    this.get();
    this.getScienceData();
  },
  methods: {
    back(showConfirm) {
      if (showConfirm) {
        this.$confirm({
          title: '提示',
          content: '确认返回列表？',
          onOk: () => this.$router.back(),
        });
      } else {
        this.$router.back();
      }
    },

    selectPost({ key } = {}) {
      this.selectedPostId = [key];
    },

    async get() {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const data = await getUnifiedTrainingConfirm(this.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }

      this.basic = data?.data?.training_data || {};
      this.posts = this.basic?.training_post_arr || [];
      this.materialData = data?.data?.upfile || [];

      if (this.posts.length) {
        this.selectPost({ key: this.posts[0].post_id });
      }
      this.postDetail = data?.data?.post_detail_arr || {};
    },

    publish() {
      this.$confirm({
        title: '确认',
        content: '确认发布该内训？',
        onOk: () => this.confirmPublish(),
      });
    },
    async confirmPublish() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await publishUnifiedTraining(this.id, { is_pub: 1 }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        if (data.error_code === 601) {
          this.$notice({
            title: '错误提示',
            content: (h) =>
              h(
                'div',
                {
                  style: { 'overflow-y': 'auto', 'max-height': '60vh' },
                },
                (data?.data || []).map((i) =>
                  h(
                    'div',
                    {
                      style: { 'margin-bottom': '10px' },
                    },
                    i,
                  ),
                ),
              ),
          });
        } else {
          this.$message.error(data?.message || '发布失败');
        }

        return;
      }
      this.$message.success(data?.message || '发布成功');
      this.back();
    },
    async getScienceData() {
      const data = await getMaterialShow(this.id);
      if (data.error_code === 0) {
        this.scienceData = data.data;
      }
    },

    downloadItem(record) {
      const fileName = record.file_name + (record.ext_str.indexOf('.') === 0 ? '' : '.') + record.ext_str;
      downloadFileExtra({ url: record.url, fileName });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-row {
  margin-top: 5px;
  margin-bottom: 10px;
}

.title {
  max-width: 260px;
  padding-right: 15px;
}

.course-title,
.exam-title {
  line-height: 2.2em;
  text-indent: 2em;
}

.course-task {
  margin-left: 1em;
}

.course-task-item {
  line-height: 2em;
}

.content-title {
  font-weight: 700;
}
</style>
