<template>
  <div class="full-size">

    <div class="flex mb-30">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id"
               class="table small-cell-table">

        <template slot="file_name"
                  slot-scope="text, record">
          <a-input v-model="record.file_name"
                   placeholder="请输入材料标题"></a-input>

        </template>

        <template slot="url"
                  slot-scope="text, record">
          <span v-if="record.type_label === 'form'">--</span>
          <div v-else>
            <a-upload name="file"
                      :multiple="false"
                      :accept="record.type_label === 'image' ? 'image/*' : '*/*'"
                      :data="{source: 'internal_training'}"
                      :file-list="record.fileList"
                      action="/public/conf/upload"
                      :remove="()=>onRemoveFile(record)"
                      @change="handleChange($event, record)">
              <!--<img v-if="record.url" :src="record.url" alt="" style="max-width: 100px; max-height: 50px;">-->
              <a-button v-if="record.url"
                        type="link">重新上传</a-button>
              <a-button v-else>
                <a-icon type="upload" />
                上传本地{{ record.type_label === 'image' ? '图片' : '文件' }}
              </a-button>
            </a-upload>
          </div>
        </template>

        <template slot="num"
                  slot-scope="text, record">
          <span v-if="record.type_label === 'form' || record.type_label === 'file'">--</span>
          <a-input-number v-else
                          v-model="record.num"
                          :min="0"></a-input-number>
        </template>
        <template slot="action"
                  slot-scope="text, record">
          <a-button type="link"
                    @click="deleteRecord(record)">
            <a-icon type="delete"></a-icon>
          </a-button>
        </template>
      </a-table>
    </div>

    <div class="flex flex-start mb-30" v-if="!isShow">
      <a-button type="primary"
                @click="addRecord">添加材料</a-button>
    </div>

    <div class="flex flex-start"
         v-if="!isShow">
      <a-button type="primary"
                @click="saveAndNext">保存并下一步</a-button>
      <a-button type="primary"
                @click="save">保存本页</a-button>
      <a-button @click="back">返回列表</a-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { menus as unifiedMenus } from '../unified/Detail.vue';
import { menus as independentMenus } from '../independent/Detail.vue';

import {
  getMaterialShow,
  postMaterialAdd,
  getUnifiedTrainingContent,
  postMaterialDelete,
} from '@/api/training/Index';

const defaultRecord = {
  id: 0,
  type: '',
  form_id: '',
  title: '',
  url: '',
  num: 0,
  endtime: undefined,
  desc: '',
  editable: true,
  type_label: '',
};

let columns = [
  {
    width: '150px',
    align: 'center',
    dataIndex: 'file_name',
    key: 'file_name',
    title: '文件名称',
    scopedSlots: { customRender: 'file_name' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'type_str',
    key: 'type_str',
    title: '文件类型',
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'url',
    key: 'url',
    title: '示例',
    scopedSlots: { customRender: 'url' },
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];


export default {
  name: 'CreateTrainingMaterial',
  components: {},
  props: {
    trainingType: { type: String, default: '1' },
    isShow: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({}),
    menus () {
      switch (+this.trainingType) {
        case 1:
          return unifiedMenus;
        case 2:
          return independentMenus;
        default:
          return [];
      }
    },
  },
  data () {
    return {
      loading: false,
      id: 0,
      published: true, // 已发布的内训也可以修改培训材料

      types: [],
      forms: [],

      data: [],
      columns,
      defaultRecord,
    };
  },
  created () {
    this.id = this.$route.query?.id || 0;

    this.get();
    this.getList();
  },
  methods: {
    back () {
      this.$confirm({
        title: '提示',
        content: '确认返回列表？',
        onOk: () => this.$router.back(),
      });
    },
    async getList () {
      this.loading = true;
      const data = await getMaterialShow(this.id);
      this.loading = false;
      if (data.error_code === 0) {
        this.data = data.data;
      }
    },
    async get () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getUnifiedTrainingContent(this.id).finally(
        () => (this.loading = false)
      );
      if (!data || data.error_code) {
        return;
      }

      this.published = !!data?.data?.training?.is_publish;
      const posts = data?.data?.post || [];

      if (data.error_code === 0) {
        posts.forEach((post) => {
          const idx = posts.findIndex((i) => i.post_id === post.post_id);
          if (idx > -1) {
            posts[idx].detail_number = post.detail_number || 0;
          }
        });
        this.posts = posts;
      }
    },
    addRecord () {
      let maxId = this.data?.length;
      if (isNaN(maxId)) {
        maxId = 1;
      }
      this.data.push({
        ...this.defaultRecord,
        record_id: maxId + 1,
        id: -1 * maxId
      });

    },
    selectForm (ev, record) {
      record.title = ev.title;
    },
    handleChange (info, record) {
      record.fileList = [info.file];
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {

        record.url = info.file.response?.data?.url;
        record.file_resource_id = info.file.response?.data?.file_resource_id;
        record.type_str = info.file.response?.data?.file_type_cn;
        console.log(info.file.response?.data);
        this.loading = false;
      }
    },
    onRemoveFile (record) {
      record.url = '';
      record.type_str = '';
      record?.fileList?.splice(0, 1);
      record.id = record.id*0.1;
    },
    deleteRecord (record) {
      if (record.id > 0) {
        this.$confirm({
          content: '确认永久删除？不可恢复',
          onOk: () => {
            this.loading = true;
            postMaterialDelete({
              material_id: record.id,
            })
              .then(() => {
                this.$message.success('删除成功');
                this.getList();
              })
              .finally(() => {
                this.loading = false;
              });
          },
        });
      } else {
        this.$confirm({
          content: '确认删除？',
          onOk: () => this.confirmDeleteRecord(record),
        });
      }
    },
    confirmDeleteRecord (record) {
      const idx = this.data.findIndex((d) => d.record_id === record.record_id);
      if (idx > -1) {
        this.data.splice(idx, 1);
      }
    },
    saveAndNext () {
      this.save().then(() => this.next(), () => { });
    },
    next () {
      let routePath = this.$route.path?.split('?')[0];

      let num = 0;
      for (let index = 0; index < this.menus.length; index++) {
        if (routePath === this.menus[index].path) {
          num = ++index;
        }
      }
      console.log(num);
      const path = this.menus[num]?.path || '';
      this.$router.replace({
        path,
        query: { id: this.id },
      });
    },
    async save () {
      //console.log(this.data);
      let isError = false;
      this.data.forEach((res) => {
        if (res.id <= 0) {
          if (!res.file_resource_id || res.file_resource_id <= 0) {
            isError = true;
            return;
          }
        }
      });
      if (isError) {
        this.$message.error('部分培训材料上传文件');
        return Promise.reject();
      }
      this.loading = true;
      for (let index = 0; index < this.data.length; index++) {
        const res = this.data[index];
        if (res.id <= 0) {
          const data = await postMaterialAdd(this.id, {
            file_name: res.file_name,
            file_resource_id: res.file_resource_id,
          });
          console.log(data);
        }
      }

      this.loading = false;
      this.$message.success('保存成功');
      this.getList();
      return Promise.resolve();
    },
  },
};
</script>




<style lang="less" scoped>
.Science {
  width: 100%;
  display: block;
}
.flex-start {
  width: 100%;
  margin: 20px 0;
}
.content {
  width: 100%;
  display: flex;
}
.content-title {
  min-width: 120px;
  text-align: center;
  margin: 10px;
}
.content-content {
  display: inline-block;
  width: 90%;
}
.content-list {
  width: 80%;
}
.content-item {
  width: 100%;
  margin: 10px;
  display: flex;
}
.content-item-text {
  min-width: 70%;
}
.content-itme-close {
  min-width: 30%;
  cursor: pointer;
  color: red;
}
</style>
