<template>
  <a-modal :title="title"
           :visible="visible"
           :confirm-loading="confirmLoading"
           :mask-closable="false"
           :width="800"
           :centered="true"
           :destroy-on-close="true"
           :afterClose="afterClose"
           @ok="confirm"
           @cancel="close">
    <block-loading v-if="confirmLoading"/>
    <a-form-model ref="formModelRef"
                  :model="form"
                  :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol">
      <a-form-model-item v-if="type === 'course'" label="选择课程" prop="detail_type_id">
        <select-course v-model="form.detail_type_id" :disabled="!!id" mode="default"></select-course>
      </a-form-model-item>
      <a-form-model-item v-if="type === 'exam'" label="选择考试" prop="detail_type_id">
        <select-exam v-model="form.detail_type_id" mode="default"></select-exam>
      </a-form-model-item>
      <a-form-model-item label="推送岗位" prop="post_id_str">
        <select-job-pure v-model="form.post_id_str" :list="posts"></select-job-pure>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getUnifiedTrainingContentItem,
  saveUnifiedTrainingContent,
} from '@/api/training/Index';

import BlockLoading from '@/components/BlockLoading.vue';
import SelectCourse from '@/components/SelectCourse.vue';
import SelectExam from '@/components/SelectExam.vue';
import SelectJobPure from '@/components/SelectJobPure.vue';

export default {
  name: 'AddCourseModal',
  components: {
    BlockLoading,
    SelectCourse,
    SelectExam,
    SelectJobPure,
  },
  computed: {
    title() {
      return `${this.id ? '修改' : '添加'}${this.type === 'course' ? '课程' : '考试'}`;
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      id: 0,
      trainingId: 0,
      type: '', // course, exam
      posts: [],

      labelCol: { span: 4 },
      wrapperCol: { span: 20 },

      form: {
        detail_type_id: '',
        post_id_str: '',
      },
      rules: {
        detail_type_id: [{ required: true, trigger: 'change', message: `请选择${this.type === 'course' ? '课程' : '考试'}` }],
        post_id_str: [{ required: true, trigger: 'select', message: '请选择岗位' }],
      },
    };
  },
  methods: {
    show({ trainingId, id, type, posts } = {}) {
      this.trainingId = trainingId;
      this.id = id;
      this.type = type;

      this.get();

      this.rules = {
        detail_type_id: [{ required: true, trigger: 'change', message: `请选择${this.type === 'course' ? '课程' : '考试'}` }],
        post_id_str: [{ required: true, trigger: 'select', message: '请选择岗位' }],
      };
      this.posts = posts.map((i) => ({ id: i.post_id, name: i.post_title }));
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      this.trainingId = undefined;
      this.id = undefined;
      this.type = '';
      this.form = {};
    },
    async confirm() {
      if (this.confirmLoading) {
        return;
      }
      this.confirmLoading = true;

      this.$refs.formModelRef.validate(async (validator) => {
        if (validator) {
          const params = {
            detail_type: this.type === 'course' ? 1 : 2,
            ...this.form,
          };
          if (this.id) {
            params.detail_id = this.id;
          }

          const data = await saveUnifiedTrainingContent(this.trainingId, params).finally(() => this.confirmLoading = false);
          if (!data || data.error_code) {
            this.$message.error(data?.message || '保存失败');
            return;
          }
          this.$message.success(data?.message || '保存成功');

          this.$emit('change', this.id ? { ...params, type: this.type } : undefined);
          this.close();
        } else {
          this.confirmLoading = false;
        }
      });
    },

    async get() {
      if (!this.id || this.confirmLoading) {
        return;
      }
      this.confirmLoading = true;

      const data = await getUnifiedTrainingContentItem(this.id).finally(() => this.confirmLoading = false);
      this.form = {
        detail_type_id: (data?.data?.detail_type_id || '') + '',
        post_id_str: data?.data?.detail_post_ids?.length ? data.data.detail_post_ids.join(',') : '',
      };
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-modal-body {
  position: relative;
}
</style>
