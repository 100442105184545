<template>
  <div class="full-size">

    <div class="flex mb-30">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="record_id"
               class="table small-cell-table">

        <template slot="type"
                  slot-scope="text, record, index">
          <single-select v-model="record.type"
                         :options="types"
                         :disabled="!record.editable || published"
                         placeholder="请选择材料类型"
                         @select="selectMaterialType($event, index)">
          </single-select>
        </template>

        <template slot="material_title"
                  slot-scope="text, record">
          <single-select v-if="record.type_label === 'form'"
                         v-model="record.form_id"
                         :options="forms"
                         :disabled="published"
                         placeholder="请选择表单"
                         @select="selectForm($event, record)"></single-select>
          <a-input v-else
                   v-model="record.title"
                   :disabled="published"
                   placeholder="请输入材料标题"></a-input>

        </template>

        <template slot="url"
                  slot-scope="text, record">
          <span v-if="record.type_label === 'form'">--</span>
          <div v-else-if="record.type_label === 'image' || record.type_label === 'file'">
            <a-upload name="file"
                      :multiple="false"
                      :accept="record.type_label === 'image' ? 'image/*' : '*/*'"
                      :data="{source: 'internal_training'}"
                      :file-list="record.fileList"
                      :disabled="published"
                      action="/public/conf/upload"
                      :remove="() => onRemoveFile(record)"
                      @change="handleChange($event, record)">
              <!--<img v-if="record.url" :src="record.url" alt="" style="max-width: 100px; max-height: 50px;">-->
              <a-button v-if="record.url"
                        :disabled="published"
                        type="link">重新上传
              </a-button>
              <a-button v-else
                        :disabled="published">
                <a-icon type="upload"/>
                上传本地{{ record.type_label === 'image' ? '图片' : '文件' }}
              </a-button>
            </a-upload>
          </div>
        </template>

        <template slot="num"
                  slot-scope="text, record">
          <span v-if="record.type_label === 'form' || record.type_label === 'file'">--</span>
          <a-input-number v-else-if="record.type_label === 'image'"
                          v-model="record.num"
                          :min="1"
                          :formatter="value => `${value}`.replace(/\.*/g, '')"
                          :parser="value => `${value}`.replace(/\.*/g, '')"
                          :disabled="published"></a-input-number>
        </template>

        <template slot="endtime"
                  slot-scope="text, record">
          <a-date-picker v-model="record.endtime"
                         :show-time="{ format: 'HH:mm' }"
                         format="YYYY-MM-DD HH:mm"
                         :disabled-date="disabledDate"
                         :disabled-time="disabledTime"
                         :disabled="published"/>
        </template>

        <template slot="desc"
                  slot-scope="text, record">
          <a-input v-model="record.desc"
                   placeholder="请输入描述信息"
                   :disabled="published"></a-input>
        </template>

        <template slot="action"
                  slot-scope="text, record">
          <a-button type="link"
                    :disabled="published"
                    @click="deleteRecord(record)">
            <a-icon type="delete"></a-icon>
          </a-button>
        </template>
      </a-table>
    </div>

    <div class="flex flex-start mb-30">
      <a-button :disabled="published"
                type="primary"
                @click="addRecord">添加回传项
      </a-button>
    </div>

    <div class="flex flex-start">
      <a-button v-if="published"
                @click="next">下一页
      </a-button>
      <template v-else>
        <a-button type="primary"
                  @click="saveAndNext">保存并下一步
        </a-button>
        <a-button type="primary"
                  @click="save">保存本页
        </a-button>
      </template>
      <a-button @click="back">返回列表</a-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { mapState } from 'vuex';
import { menus as unifiedMenus } from '../unified/Detail.vue';
import { menus as independentMenus } from '../independent/Detail.vue';

import {
  getUnifiedTrainingMaterialTypes,
  getUnifiedTrainingMaterial,
  saveUnifiedTrainingMaterial,
} from '@/api/training/Index';

import SingleSelect from './SingleSelect';

const defaultRecord = {
  record_id: 0,
  type: '',
  form_id: '',
  title: '',
  url: '',
  num: 0,
  endtime: undefined,
  desc: '',
  editable: true,
  type_label: '',
};

const columns = [
  {
    width: '100px',
    align: 'center',
    dataIndex: 'type',
    key: 'type',
    title: '资料类型',
    scopedSlots: { customRender: 'type' },
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '标题',
    scopedSlots: { customRender: 'material_title' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'url',
    key: 'url',
    title: '示例',
    scopedSlots: { customRender: 'url' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'num',
    key: 'num',
    title: '数量',
    scopedSlots: { customRender: 'num' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'endtime',
    key: 'endtime',
    title: '最晚提交时间',
    scopedSlots: { customRender: 'endtime' },
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'desc',
    key: 'desc',
    title: '描述',
    scopedSlots: { customRender: 'desc' },
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'CreateTrainingMaterial',
  components: {
    SingleSelect,
  },
  props: {
    trainingType: { type: String, default: '1' },
  },
  computed: {
    ...mapState({}),
    menus() {
      switch (+this.trainingType) {
        case 1:
          return unifiedMenus;
        case 2:
          return independentMenus;
        default:
          return [];
      }
    },
    disabledDate() {
      return (current) => {
        if (!current) {
          return false;
        }
        return current < moment().startOf('days');
      };
    },
    disabledTime() {
      return (current) => ({
        disabledHours: () => {
          if (!current) {
            return [];
          }
          if (current.isSame(moment(), 'days')) {
            return this.range(0, moment().hours());
          }
          return [];
        },
        disabledMinutes: () => {
          if (!current) {
            return [];
          }
          if (current.isSame(moment(), 'hours')) {
            return this.range(0, moment().minutes() + 1);
          }
          return [];
        },
      });
    },
    range() {
      return (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };
    },
  },
  data() {
    return {
      loading: false,
      id: 0,
      published: true,

      types: [],
      forms: [],

      data: [],
      columns,
      defaultRecord,
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;

    this.published = !!this.id;
    this.getTypes();
    this.get();
  },
  methods: {
    back() {
      this.$confirm({
        title: '提示',
        content: '确认返回列表？',
        onOk: () => this.$router.back(),
      });
    },

    async getTypes() {
      const data = await getUnifiedTrainingMaterialTypes();
      const types = data?.data?.upfile_type || [];
      this.forms = data?.data?.form || [];

      if (Object.prototype.toString.call(this.types) !== `'[object Array]'`) {
        this.types = [];
        Object.keys(types).forEach((t) => {
          this.types.push(types[t]);
        });
      } else {
        this.types = types;
      }
      this.types = [
        ...this.types.map((t) => {
          switch (+t.id) {
            case 1:
              return { ...t, type: 'image' };
            case 2:
              return { ...t, type: 'file' };
            case 3:
              return { ...t, type: 'form' };
          }
        }),
      ];
    },
    async get() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getUnifiedTrainingMaterial(this.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      this.published = !!data?.meta?.is_publish;
      const result = data?.data || [];
      this.data = result.map((r, i) => ({
        title: r.title,
        id: r.id,
        num: r.num,
        endtime: moment(r.endtime_str, 'YYYY-MM-DD HH:mm'),
        desc: r.desc,
        record_id: i + 1,
        editable: false,
        type: r.type + '',
        form_id: r?.example_resource_info[0]?.id + '',
        type_label: r.type === 1 ? 'image' : r.type === 2 ? 'file' : 'form',
        url: r.example_resource_info?.length
          ? r.example_resource_info[0].url
          : '',
        fileList: [],
      }));
    },

    addRecord() {
      const maxId = this.data?.length
        ? this.data.reduce((p, c) => ({
          record_id: Math.max(p.record_id, c.record_id),
        })).record_id
        : 0;
      this.data.push({
        ...this.defaultRecord,
        record_id: maxId + 1,
      });
    },

    selectMaterialType(ev, index) {
      this.data = [
        ...this.data.map((d, i) => {
          if (i === index) {
            return {
              ...d,
              type_label: ev.type,
              url: '',
              num: 1,
              editable: true,
              fileList: [],
            };
          }
          return { ...d };
        }),
      ];
    },

    selectForm(ev, record) {
      record.title = ev.title;
    },

    handleChange(info, record) {
      if (info.file.status === 'removed') {
        record.fileList = [];
      } else {
        record.fileList = [info.file];
      }
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.loading = false;
        record.url = info.file.response?.data?.url;
      }
    },
    onRemoveFile(record) {
      this.data = [
        ...this.data.map((d) => ({
          ...d,
          url: record.record_id === d.record_id ? '' : d.url,
        })),
      ];
      return true;
    },

    deleteRecord(record) {
      this.$confirm({
        content: '确认删除？',
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    confirmDeleteRecord(record) {
      const idx = this.data.findIndex((d) => d.record_id === record.record_id);
      if (idx > -1) {
        this.data.splice(idx, 1);
      }
    },

    check() {
      return this.data.every((d) => {
        if (!d.type) {
          this.$message.warn('请选择材料类型');
          return false;
        }
        if (d.type_label !== 'form' && !d.title) {
          this.$message.warn('请输入材料标题');
          return false;
        }
        // if (+d?.type !== 3 && d?.url === '') {
        //   this.$message.warn('请上传示例文件');
        //   return false;
        // }
        if (d.type_label === 'form' && !d.form_id) {
          this.$message.warn('请选择表单');
          return false;
        }
        if (!d.endtime) {
          this.$message.warn('请选择最晚提交时间');
          return false;
        }
        return true;
      });
    },

    async save() {
      if (this.loading) {
        return Promise.reject(new Error('Loading'));
      }
      this.loading = true;

      if (!this.check()) {
        this.loading = false;
        return Promise.reject(new Error('Check Failed'));
      }

      const sourceData = this.data.map((d) => {
        const item = {
          type: d.type,
          title: d.title,
          endtime: d.endtime.format('YYYY-MM-DD HH:mm'),
          desc: d.desc,
        };

        if (d.type_label === 'form') {
          item.form_id = d.form_id;
          item.id = d.id;
        } else {
          item.id = d.id;
          item.url = d.url;

          if (d.type_label === 'image') {
            item.num = d.num;
          }
        }

        return item;
      });


      const data = await saveUnifiedTrainingMaterial(this.id, {
        inform_data_json: JSON.stringify(sourceData || []),
      }).finally(() => (this.loading = false));
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return Promise.reject(data);
      }
      this.$message.success(data?.message || '保存成功');
      this.get();
      return Promise.resolve(data);
    },

    saveAndNext() {
      this.save().then(() => this.next());
    },

    next() {
      let routePath = this.$route.path?.split('?')[0];

      let num = 0;
      for (let index = 0; index < this.menus.length; index++) {
        if (routePath === this.menus[index].path) {
          num = ++index;
        }
      }
      console.log(num);
      const path = this.menus[num]?.path || '';
      this.$router.replace({
        path,
        query: { id: this.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
