<template>
  <a-modal :title="title"
           :visible="shown"
           :mask-closable="true"
           :width="800"
           :centered="true"
           :destroy-on-close="true"
           @ok="close"
           @cancel="close">
    <div v-if="!list || !list.length" class="ant-empty">该表单为空
    </div>
    <a-form-model ref="formModelRef"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                  disabled>
      <a-form-model-item v-for="(item, idx) in list"
                         :key="idx"
                         disabled
                         :label="item.field">
        <a-date-picker v-if="item.type === 'dateplug'"
                       v-model="item.value"
                       disabled/>
        <a-input v-else
                 v-model="item.value"
                 :placeholder="'请填写' + item.field"
                 disabled/>
      </a-form-model-item>
    </a-form-model>


    <div slot="footer"
         class="flex">
      <a-button class="btn btn-cancel"
                @click="close">
        关闭
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import * as moment from 'moment';

export default {
  name: 'FormModal',
  data() {
    return {
      shown: false,

      labelCol: { span: 4 },
      wrapperCol: { span: 20 },

      title: '',
      list: [],
    };
  },
  methods: {
    show({ list, title } = {}) {
      this.title = title || '';
      this.list = (list || []).map((i) => {
        const item = { ...i };
        if (i.type === 'dateplug') {
          item.value = moment(i.value, 'YYYY-MM-DD HH:mm:ss');
        }

        return item;
      });

      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    afterClosed() {
      this.title = '';
      this.list = [];
    },
  },
};
</script>

<style scoped lang="scss">
.ant-empty {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
