<template>
  <div class="postconfig-deploy">
    <div class="postconfig-verfy">
      <CommonTitle :titles="titles"></CommonTitle>
    </div>
    <div class="pd-content">
      <div class="pd-content-left">
        <h3>配置岗位</h3>
      </div>
      <div class="pd-content-right">
        <div>
          <SelectJobGrouped
            v-model="form.post_str"
            :disabledIds="dataShow.disabledIds"
            :list="dataShow.list"
          ></SelectJobGrouped>
        </div>
        <div class="pd-content-tip">配置岗位不能与审核岗位重复</div>
      </div>
    </div>
    <div class="pd-content-btn">
      <a-button type="primary" @click="savePost"> 提交</a-button>
    </div>
    <div class="loading">
      <Loading v-show="isLoading"></Loading>
    </div>
  </div>
</template>


<script>
import Loading from '../components/Loading.vue';
import CommonTitle from '@components/CommonTitle';
import SelectJobGrouped from '@components/SelectJobGrouped';
import { getAllPost, postSetConfPost } from '@/api/training/PostConfig';

let titles = [
  { title: '内训管理' },
  { title: '内训岗位权限管理' },
  { title: '配置岗位' },
];

export default {
  name: 'Deploy',
  data() {
    return {
      titles,
      form: {
        post_str: '',
      },
      dataShow: {
        disabledIds: '',
        list: [],
      },
      isLoading: true,
    };
  },
  components: {
    CommonTitle,
    SelectJobGrouped,
    Loading,
  },
  methods: {
    getList() {
      this.isLoading = true;
      getAllPost()
      .then((res) => {
        if (res.error_code === 0) {
          this.dataShow.disabledIds = res?.data?.manage_post?.join(',') ?? '';
          this.dataShow.list = res?.data?.post_list ?? '';
          this.form.post_str = res?.data?.conf_post?.join(',') ?? '';
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    savePost() {
      this.isLoading = true;
      postSetConfPost(this.form)
      .then((res) => {
        if (res.error_code === 0) {
          this.$message.success('保存成功!');
          this.getList();
        } else {
          this.$message.error('保存失败!', res.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.postconfig-deploy {
  position: relative;
}

.pd-content {
  margin-top: 30px;
  display: flex;
  width: 100%;
}

.pd-content-list {
  display: block;
  width: 100%;
}

.pd-content-item {
  display: flex;
  width: 100%;
}

.pd-content-left {
  flex: 1;
  min-width: 150px;
  text-align: right;
}

.pd-content-right {
  flex: 8;
  margin-left: 20px;
}

.pd-content-right div span {
  margin: 10px;
}

.pd-content-tip {
  margin-top: 30px;
  display: block;
  width: 100%;
}

.pd-content-btn {
  margin-top: 30px;
  display: block;
  width: 100%;
  text-align: center;
}
</style>
