<template>
  <div class="uni-add">
    <div class="sr-list">
      <div class="sr-list-item">
        <span class="sr-item-title">内容回传:</span>
        <span class="sr-item-content">
          <span>按期提交</span>
          <span class="item-content-input">
            <a-input-number
              class="item-content-input"
              :min="1"
              :max="100"
              v-model="model.putInTime"
            />
          </span>
          <span>分</span>
          <span class="list-item-title">逾期提交</span>
          <span class="item-content-input">
            <a-input-number
              class="item-content-input"
              :min="1"
              :max="100"
              v-model="model.putOutTime"
            />
          </span>
          <span>分</span>
          <div class="sr-tip">每项提交物单独计算</div>
        </span>
      </div>
      <div class="sr-list-item">
        <span class="sr-item-title">评价反馈:</span>
        <span class="sr-item-content">
          <span class="item-content-input">
            <a-input-number
              class="item-content-input"
              :min="1"
              :max="100"
              v-model="model.scoreFeedback"
            />
          </span>
          分
        </span>
      </div>
      <div class="sr-top">
        <div class="sr-list-item">
          <span class="sr-item-title">自主内训使用考试平台:</span>
          <span class="sr-item-content">
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.usingTheExamBySubject"
              />
            </span>
            分
          </span>
        </div>
        <div class="sr-list-item">
          <span class="sr-item-title">自主内训使用平台下发课件:</span>
          <span class="sr-item-content">
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.usingTheCoursewareBySubject"
              />
            </span>
            分
          </span>
        </div>
        <div class="sr-list-item">
          <span class="sr-item-title">线下参训率:</span>
          <span class="sr-item-content">
            等于百分比数值 *
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.minOfflineParticipationRate"
              />
            </span>
            分
            <div class="sr-tip">
              线下参训率=在全部线下环节均完成打卡的人数/应训人数*100%
            </div>
          </span>
        </div>
        <div class="sr-list-item">
          <span class="sr-item-title">一次考试通过率:</span>
          <span class="sr-item-content">
            等于百分比数值*
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.passExamByOnce"
              />
            </span>
            分
            <div class="sr-tip">
              一次考试通过率=在全部考试均1次通过的人数/应训人数*100%
            </div>
          </span>
        </div>
        <div class="sr-list-item">
          <span class="sr-item-title">总体考试通过率:</span>
          <span class="sr-item-content">
            等于百分比数值*
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.unifiedMinPassRate"
              />
            </span>
            分
            <div class="sr-tip">
              总体考试通过率=在全部考试中做好成绩达到通过标准的人数/应训人数*100%
            </div>
          </span>
        </div>
        <div class="sr-list-item">
          <span class="sr-item-title">线上内容完成率:</span>
          <span class="sr-item-content">
            等于百分比数值*
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.onlineFinishingRate"
              />
            </span>
            分
            <div class="sr-tip">
              线上内容完成率=完成全部线上学习内容的人数/应训人数*100%
            </div>
          </span>
        </div>
        <h3 class="text-other-title">其他加分项</h3>
        <div class="sr-list-item">
          <span class="sr-item-title">共享资料并通过审核:</span>
          <span class="sr-item-content">
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.shareCourseware"
              />
            </span>
            分
          </span>
        </div>
        <div class="sr-list-item">
          <span class="sr-item-title">总经理、投资人参与:</span>
          <span class="sr-item-content">
            <span class="item-content-input">
              <a-input-number
                class="item-content-input"
                :min="1"
                :max="100"
                v-model="model.managerSignScore"
              />
            </span>
            分
          </span>
        </div>
        <div class="sb-item-btn">
          <a-button type="primary" @click="save"> 提交 </a-button>
          <a-button v-if="+$route.query.back === 1" @click="$router.back()"
            >返回</a-button
          >
        </div>
      </div>
    </div>
    <div class="loading">
      <Loading v-show="isLoading"></Loading>
    </div>
  </div>
</template>


<script>
import Loading from '../components/Loading.vue';
import { postConfigSave, getConfigShow } from '@/api/training/ScoreRule.js';
let label = [];

let model = {
  putInTime: '',
  putOutTime: '',
  scoreFeedback: '',
  usingTheExamBySubject: '',
  usingTheCoursewareBySubject: '',
  minOfflineParticipationRate: '',
  passExamByOnce: '',
  unifiedMinPassRate: '',
  onlineFinishingRate: '',
  studyByCourseTask: '',
  label: label,
  shareCourseware: '',
  managerSignScore: ''
};
export default {
  data() {
    return {
      label,
      model,
      isLoading: false
    };
  },
  components: {
    Loading
  },
  methods: {
    getData() {
      this.isLoading = true;
      getConfigShow({ type: 1 })
        .then((res) => {
          if (res.error_code === 0) {
            for (let index in res.data.default) {
              let item = res.data.default[index];
              this.model[item.key] = item.value;
            }
            console.log(this.model);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    save() {
      for (let key in this.model) {
        if (this.model[key] < 0) {
          this.$message.error('请输入正确分数!');
          return;
        }
      }
      if (
        model.minOfflineParticipationRate < 0 ||
        model.minOfflineParticipationRate > 100
      ) {
        this.$message.error('请输入正确百分比 0- 100 之间!');
        return;
      }
      if (model.passExamByOnce < 0 || model.passExamByOnce > 100) {
        this.$message.error('请输入正确百分比 0- 100 之间!');
        return;
      }
      if (model.onlineFinishingRate < 0 || model.onlineFinishingRate > 100) {
        this.$message.error('请输入正确百分比 0- 100 之间!');
        return;
      }
      if (model.unifiedMinPassRate < 0 || model.unifiedMinPassRate > 100) {
        this.$message.error('请输入正确百分比 0- 100 之间!');
        return;
      }
      this.isLoading = true;
      postConfigSave(this.model)
        .then((res) => {
          console.log(res);
          this.$message.success(res.message);
          if (+this.$route.query.back === 1) {
            this.$router.back();
          } else {
            this.getData();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
      return;
    }
  },
  created: function () {
    this.getData();
  }
};
</script>

<style scoped lang="scss">
.uni-add {
  width: 100%;
  float: left;
  position: relative;
  margin: 5px 20px;
}
.sr-left {
  flex: 1;
}
.sr-right {
  flex: 1;
  border-left: 1px solid #000;
  position: relative;
}
.sr-top {
  margin-top: 30px;
}
.sr-list-item {
  display: flex;
  margin: 5px 0;
  width: 100%;
  min-width: 35px;
  max-height: 70px;
  line-height: 35px;
}
.list-item-title {
  width: auto;
  margin: 20px;
}

.sr-tip {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 0;
  margin: 10px 0;
}
.item-content-input {
  width: 80px;
  max-width: 80px;
  margin: 5px;
}
.sr-item-title {
  line-height: 43px;
}
.sr-item-content {
  flex: 8;
  margin-left: 20px;
}
.sr-item-score {
  flex: 1;
  text-align: center;
}
.sr-score-left {
  text-align: left;
  margin-left: 40px;
}
.sr-score-right {
  text-align: right;
  max-width: 150px;
}
.score-tip {
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  left: 10px;
  bottom: 10px;
}
.right-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.sb-item-btn {
  text-align: center;
}
.text-other-title {
  margin-top: 20px;
  font-weight: 700;
}
</style>
