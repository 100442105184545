<template>
  <div class="cloud-list">
    <div class="header-breadcrumb">
      <CommonTitle :titles="titles"> </CommonTitle>
    </div>
    <div class="search-list">
      <div class="search-item">
        <span class="search-item-title">标题:</span>
        <span class="search-item-input">
          <a-input placeholder="请输入关键字"
                   v-model="param.title"
                   :allow-clear="true"
                   class="search-item-input" />
        </span>
      </div>
      <div class="search-item">
        <span class="search-item-title">公开日期:</span>
        <span class="search-item-input">
          <a-range-picker @change="getDate"
                          v-model="defaultDate"
                          class="search-item-input"></a-range-picker>
        </span>
      </div>
      <div class="search-item">
        <span class="search-item-title">类型:</span>
        <span class="search-item-input">
          <a-select default-value="lucy"
                    style="width: 120px"
                    @change="handleChange"
                    class="search-item-input"
                    v-model="param.resource_type">
            <a-select-option value="0"> 不限 </a-select-option>
            <a-select-option value="1"> 文档 </a-select-option>
            <a-select-option value="2"> 表格 </a-select-option>
            <a-select-option value="3"> pdf </a-select-option>
            <a-select-option value="4"> ppt </a-select-option>
            <a-select-option value="5"> 图片 </a-select-option>
            <a-select-option value="6"> 视频 </a-select-option>
            <a-select-option value="7"> 音频 </a-select-option>
            <a-select-option value="8"> 其他 </a-select-option>
          </a-select>
        </span>
      </div>
      <div class="search-item">
        <span class="search-item-title">组织机构:</span>
        <span class="search-item-input">
          <SelectOrg class="search-item-selectorg"
                     ref="selectOrg"
                     :show-label="false"
                     :disabled="readOnly"
                     @select="selectOrg"
                     v-model="orgIds"></SelectOrg>
        </span>
      </div>
      <div class="search-item">
        <span class="search-item-title">公开人:</span>
        <span class="search-item-input">
          <a-input placeholder="请选择输入关键字"
                   v-model="param.username"
                   :allow-clear="true"
                   class="search-item-input" />
        </span>
      </div>
      <div class="search-item">
        <a-button type="primary"
                  class="search-item-btn"
                  @click="getList(1,10)">
          搜索
        </a-button>
        <a-button class="search-item-btn"
                  @click="clearSearch">重置</a-button>
      </div>
    </div>
    <div class="tc-list">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               @click="getId(item)"
               row-key="id"
               :loading="isLoading"
               bordered>
        <span slot="title1"
              slot-scope="text, item">
          <a :href=" isOffice(item) ?  `https://view.officeapps.live.com/op/embed.aspx?src=${item.resource_url}` :item.resource_url "
             target="_blank">{{ text }}</a>
        </span>
        <span slot="id"
              slot-scope="text, item"
              class="table-btn-list">
          <a @click="lowerCloud(item)"
             class="table-btn-item"
             :disabled="isdisabled"
             v-if="item.is_status === 1">下架</a>
          <a @click="lowerCloud(item)"
             class="table-btn-item"
             :disabled="isdisabled"
             v-if="item.is_status === 0">上架</a>
          <a @click="scoreCloud(item)"
             class="table-btn-item"
             :disabled="isdisabled"
             v-if="item.score <= 0 || item.score === ''">打分</a>
          <a @click="download(item)"
             target="_blank"
             class="table-btn-item"
             :disabled="isdisabled">下载</a>
          <a @click="deleteCloud(item)"
             class="table-btn-item"
             :disabled="isdisabled">删除</a>
        </span>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
      <!-- <div class="tc-paging">
        <a-pagination
          :current="pagination.current_page"
          :total="pagination.total"
          :page-size="pagination.per_page"
          :loading="isLoading"
          @change="getPage"
        />
      </div> -->
    </div>
    <a-modal v-model="visible"
             title="公开云打分"
             :ok-button-props="{ props: { disabled:  isDisabled()  } }"
             @ok="handleOk">
      <a-input-number placeholder="请输入正整数"
                      id="inputNumber"
                      v-model="score"
                      :min="0"
                      @change="onChange" />
    </a-modal>
  </div>
</template>
<script>
import SelectOrg from '@components/SelectOrg';
import CommonTitle from '@components/CommonTitle';
import Pagination, { getPagination } from '@components/Pagination.vue';
import { downloadFileExtra } from '@/utils/JsDownload';
import Common from '@/utils/Common';
import { deleteShutoff, getCloudList, postScoreCloud, postShutoff } from '@/api/training/Cloud';

let titles = [
  {
    title: '内训管理',
  },
  {
    title: '公开云管理',
  },
  {
    title: '公开云列表',
  },
];
let param = {
  title: '',
  start_at: '',
  end_at: '',
  resource_type: '0',
  org_id: '',
  username: '',
  page: 1,
  per_page: 10,
};
const columns = [
  {
    title: '标题',
    key: 'title',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title1' },
    align: 'center',
  },
  {
    title: '类型',
    dataIndex: 'resource_type_str',
    key: 'resource_type_str',
    align: 'center',
  },
  {
    title: '大区',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    align: 'center',
  },
  {
    title: '店名',
    key: 'org_name',
    dataIndex: 'org_name',
    align: 'center',
  },
  {
    title: '公开人',
    key: 'truename',
    dataIndex: 'truename',
    align: 'center',
  },
  {
    title: '电话',
    key: 'username',
    dataIndex: 'username',
    align: 'center',
  },
  {
    title: '公开时间',
    key: 'pass_at_2',
    dataIndex: 'pass_at_2',
    align: 'center',
  },
  {
    title: '打分',
    key: 'score',
    dataIndex: 'score',
    align: 'center',
  },
  {
    title: '打分时间',
    key: 'score_at_2',
    dataIndex: 'score_at_2',
    align: 'center',
  },
  {
    title: '操作',
    key: 'id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'center',
  },
];

let show_model = {
  visible: false,
  confirm_loading: false,
  model_text: '',
  id: 0,
  isOk: 0,
};

export default {
  data () {
    return {
      titles,
      orgIds: '',
      readOnly: false,
      defaultSelectedIds: '',
      param,
      columns,
      data: [],
      loading: false,
      pagination: getPagination(),
      show_model,
      defaultDate: ['', ''],
      score: '',
      isLoading: false,
      visible: false,
      isdisabled: false,
      cloud_id: 0,
    };
  },
  components: {
    SelectOrg,
    CommonTitle,
    Pagination,
  },
  methods: {
    getDate (date, dateString) {
      this.param.start_at = dateString[0];
      this.param.end_at = dateString[1];
    },
    selectOrg (event) {
      const data = event?.data || [];
      this.param.org_id = data.map((i) => i.id).join(',');
    },
    handleChange (value) {
      this.resource_type = value;
    },
    getList (page, pageSize) {
      this.isLoading = true;
      this.param.page = page || this.pagination.current || 1;
      this.param.per_page = pageSize || this.pagination.pageSize;
      getCloudList(this.param).then((res) => {
        if (res.error_code === 0) {
          this.data = res.data;
          const pagination = res?.meta?.pagination || {};
          this.pagination = Common.updatePagination(
            this.pagination,
            pagination
          );
          this.isLoading = false;
        } else {
          this.data = [];
        }
      }).finally(() => this.isLoading = false);
    },
    deleteCloud (item) {
      let text = `确认要删除"${item.title}"吗?`;
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          deleteShutoff(item.id)
            .then((res) => {
              this.isLoading = false;
              if (res.error_code >= 0) {
                this.$message.success('删除成功!');
                this.getList();
              }
              if (res.error_code < 0) {
                this.$message.error('删除失败!');
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
      });
    },
    lowerCloud (item) {
      let title_str = item.is_status === 1 ? '下架' : '上架';
      let text = `确认要${title_str}"${item.title}"吗?`;
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          this.isLoading = true;
          postShutoff(item.id)
            .then((res) => {
              if (res.error_code >= 0) {
                this.$message.success(`${title_str}成功`);
                this.getList();
              }
              if (res.error_code < 0) {
                this.$message.success(`${title_str}失败,请重试!`);
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
      });
    },
    scoreCloud (item) {
      this.visible = true;
      this.cloud_id = item.id;
      this.score = item.score;
    },
    handleOk () {
      if (this.score > 0) {
        this.isLoading = true;
        postScoreCloud({
          score: this.score,
          cloud_id: this.cloud_id,
        }).then((res) => {
          this.isLoading = false;
          if (res.error_code >= 0) {
            this.$message.success(`打分成功!`);

            this.getList();
          } else {
            this.$message.error(`请输入正确分数,重新打分`);
          }
        }).finally(() => {
          this.visible = false;
        });
      } else {
        this.value = true;
        this.$message.error(`请输入正确分数,重新打分`);

      }
    },
    getPage (pagination) {
      if (this.pagination.current_page !== pagination.current) {
        this.pagination.current_page = pagination.current;
        this.getList();
      }
    },

    clearSearch: function () {
      this.param = {
        title: '',
        start_at: '',
        end_at: '',
        resource_type: '0',
        org_id: '',
        username: '',
        page: 1,
        per_page: 20,
      };
      this.defaultDate = ['', ''];
      this.defaultSelectedIds = '';
      this.orgIds = '';
      this.getList(1, 10);
    },
    saveShowModel: function () {
      let that = this;
      return new Promise(function (resolve) {
        let interval = setInterval(function () {
          if (that.show_model.isOk !== 0) {
            clearInterval(interval);
            if (that.show_model.isOk === 1) {
              resolve(1);
            } else {
              resolve(2);
            }
          }
        }, 500);
      });
    },
    onPageChange (page, pageSize) {
      this.getList(page, pageSize);
    },
    onSizeChange (current, size) {
      this.getList(1, size);
    },
    isOffice (item) {
      return (
        item.resource_type === 1 ||
        item.resource_type === 2 ||
        item.resource_type === 4
      );
    },
    isDisabled () {
      return this.score < 0 || this.score * 10 % 10 !== 0;
    },
    onChange (value) {
      if (value * 10 % 10 !== 0) {
        this.$message.error("请输入正整数");
      }
    },
    download (item) {
      const url = item.pc_resource_url.split('?')[0];
      /(\.[^.]+)$/g.test(url);
      downloadFileExtra({
        url: item.pc_resource_url,
        fileName: item.title + RegExp.$1
      }).then(() => {
        this.$message.success("正在下载...");
      }, () => {
        this.$message.error("下载失败");
      })
    }
  },
  created () {
    this.clearSearch();
  },
};
</script>

<style scoped>
.cloud-list {
  position: relative;
}
.search-list {
  margin: 10px;
  display: flex;
  /* width: 100%; */
  flex-wrap: wrap;
}
.search-item {
  display: flex;
  vertical-align: middle;
  line-height: 40px;
  margin: 10px;
  text-align: left;
}
.search-item-title {
  vertical-align: middle;
  min-width: 60px;
}
.search-item-input {
  margin: 0 10px;
  flex: 6;
}
.search-item-btn {
  margin-top: 3px;
}
.search-item-selectorg {
  margin: 3px 10px;
}
.tc-list {
  margin: 15px;
}
.table-btn-list {
  display: flex;
  /* width: 100%; */
  flex-wrap: wrap;
}
.table-btn-item {
  flex: 1;
  min-width: 20%;
}
.tc-paging {
  display: block;
  text-align: center;
  margin-top: 10px;
}
.ant-table-title {
  display: none !important;
}
</style>
