<template>
  <div class="full-size">
    <a-space v-for="(item) in posts"
             :key="item.id"
             class="flex flex-start mb-10">
      <span class="flex flex-end mr-30 post-title">{{ item.post_title }}最低参与人数</span>
      <a-input-number v-model="item.detail_number"
                      :min="0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      :disabled="loading || published"></a-input-number>
      <span class="ml-30 hint-text">填0视为岗位全员参与</span>
    </a-space>

    <a-divider />

    <template v-if="+trainingType === 1">
      <div class="flex flex-end mb-10">
        <a-button type="primary"
                  :disabled="published"
                  @click="showAddCourseModal()">添加课程</a-button>
        <a-button type="primary"
                  :disabled="published"
                  @click="showAddExamModal()">添加考试</a-button>
      </div>

      <div class="flex mb-30">
        <a-table :columns="columns"
                 :data-source="data"
                 :pagination="false"
                 :loading="loading"
                 bordered
                 row-key="id"
                 class="table small-cell-table">

          <template slot="action"
                    slot-scope="text, record">
            <a-button type="link"
                      :disabled="published"
                      @click="editRecord(record)">编辑</a-button>
            <a-button type="link"
                      :disabled="published"
                      @click="deleteRecord(record)">删除</a-button>
          </template>
        </a-table>
      </div>
    </template>

    <div class="flex flex-start">
      <a-button v-if="published"
                @click="next">下一页</a-button>
      <template v-else>
        <a-button type="primary"
                  @click="saveAndNext">保存并下一步</a-button>
        <a-button type="primary"
                  @click="save">保存本页</a-button>
      </template>
      <a-button @click="back">返回列表</a-button>
    </div>

    <add-course-modal ref="addCourseModalRef"
                      @change="addCourseChange" />
    <full-content-modal ref="fullContentModalRef" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { menus as unifiedMenus } from '../unified/Detail.vue';
import { menus as independentMenus } from '../independent/Detail.vue';

import {
  getUnifiedTrainingContent,
  saveUnifiedTrainingPostPeopleCount,
  deleteUnifiedTrainingContentItem,
} from '@/api/training/Index';

import AddCourseModal from './AddCourseModal';
import FullContentModal from '../../components/FullContentModal.vue';

export default {
  name: 'CreateTrainingContent',
  components: {
    AddCourseModal,
    FullContentModal,
  },
  props: {
    trainingType: { type: String, default: '1' },
  },
  computed: {
    ...mapState({}),
    menus() {
      switch (+this.trainingType) {
        case 1:
          return unifiedMenus;
        case 2:
          return independentMenus;
        default:
          return [];
      }
    },
  },
  data() {
    return {
      loading: false,
      id: 0,
      published: true,

      posts: [],
      data: [],

      columns: [
        {
          width: '100px',
          align: 'center',
          dataIndex: 'detail_type_title',
          key: 'detail_type_title',
          title: '类型',
        },
        {
          width: '150px',
          align: 'center',
          dataIndex: 'detail_title',
          key: 'detail_title',
          title: '名称',
        },
        {
          width: '200px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'detail_post_str',
          key: 'detail_post_str',
          title: '推送岗位',
          customCell: (record) => {
            return {
              style: {
                width: '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () =>
                  this.showFullContent(
                    record['detail_post_str'],
                    '推送岗位详情'
                  ),
              },
            };
          },
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;

    this.published = !!this.id;
    this.get();
  },
  methods: {
    back() {
      this.$confirm({
        title: '提示',
        content: '确认返回列表？',
        onOk: () => this.$router.back(),
      });
    },

    async get() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getUnifiedTrainingContent(this.id).finally(
        () => (this.loading = false)
      );
      if (!data || data.error_code) {
        return;
      }

      this.published = !!data?.data?.training?.is_publish;
      this.data = data?.data?.detail || [];
      const posts = data?.data?.post || [];

      this.posts.forEach((post) => {
        const idx = posts.findIndex((i) => i.post_id === post.post_id);
        if (idx > -1) {
          posts[idx].detail_number = post.detail_number || 0;
        }
      });
      this.posts = posts;
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({
          content: content.split(','),
          title,
        });
      }
    },

    deleteRecord(record) {
      this.$confirm({
        content: `确定删除该${record.detail_type === 1 ? '课程' : '考试'}？`,
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    async confirmDeleteRecord(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteUnifiedTrainingContentItem(record.id).finally(
        () => (this.loading = false)
      );
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.get();
    },

    editRecord(record) {
      this.showAddModal(record, record.detail_type === 1 ? 'course' : 'exam');
    },
    showAddCourseModal(record) {
      this.showAddModal(record, 'course');
    },
    showAddExamModal(record) {
      this.showAddModal(record, 'exam');
    },

    showAddModal(record = {}, type) {
      if (this.$refs.addCourseModalRef?.show) {
        this.$refs.addCourseModalRef.show({
          trainingId: this.id,
          ...record,
          type,
          posts: this.posts,
        });
      }
    },

    addCourseChange() {
      this.get();
    },

    async save() {
      if (this.loading) {
        return Promise.reject(new Error('Loading'));
      }
      this.loading = true;

      const postsCount = (this.posts || []).map((p) => ({
        post_id: p.post_id,
        detail_number: p.detail_number,
      }));
      const data = await saveUnifiedTrainingPostPeopleCount(this.id, {
        add_str: JSON.stringify(postsCount?.length ? postsCount : []),
      }).finally(() => (this.loading = false));
      if (!data || data.error_code) {
        this.$message.error(data?.message || `保存失败`);
        return Promise.reject(data);
      }

      this.$message.success(data?.message || `保存成功`);
      if (!this.id) {
        this.id = +data.data?.training_id || 0;
        await this.$router.replace({
          path: this.$route.path,
          query: { id: this.id },
        });
      }
      return Promise.resolve(data.data);
    },

    saveAndNext() {
      this.save().then(() => this.next());
    },

    next() {
      let routePath = this.$route.path?.split('?')[0];

      let num = 0;
      for (let index = 0; index < this.menus.length; index++) {
        if (routePath === this.menus[index].path) {
          num = ++index;
        }
      }
      console.log(num);
      const path = this.menus[num]?.path || '';
      this.$router.replace({
        path,
        query: { id: this.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.post-title {
  width: 240px;
}

.hint-text {
  flex: auto;
  font-size: 12px;
  color: #999999;
}
</style>
