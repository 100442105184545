<template>
  <create-training-content training-type="2"/>
</template>

<script>
import CreateTrainingContent from '../components/CreateTrainingContent.vue';

export default {
  name: 'Content',
  components: { CreateTrainingContent },
};
</script>

<style scoped lang="scss">

</style>
