<template>
  <div class="selfbuilt">
    <h2>达标要求</h2>

    <div class="sb-list">
      <h3 class="sb-title">
        <span class="sb-title-title">考评项</span>
        <span class="sb-title-content title-content-title">要求/描述</span>
      </h3>
      <div class="sb-item">
        <div class="sb-title">
          <span class="sb-title-title">内训计划</span>
          <span class="sb-title-content">
            <a-input v-model="model.internalTrainPlan" />
          </span>
        </div>
      </div>
      <div class="sb-item">
        <div class="sb-title">
          <span class="sb-title-title">现场照片</span>
          <span class="sb-title-content">
            <a-input v-model="model.siteImages" />
          </span>
        </div>
      </div>
      <div class="sb-item">
        <div class="sb-title">
          <span class="sb-title-title">总体考试通过率要求</span>
          <span class="sb-title-content">
            <span class="sb-socre-input">
              <a-input-number class="sb-socre-input"
                              :min="1"
                              :max="100"
                              v-model="model.subjectMinPassRate" />
            </span>
            %
          </span>
        </div>
      </div>
      <div class="sb-item">
        <div class="sb-title">
          <span class="sb-title-title">使用e旗学平台学习课件</span>
        </div>
      </div>
      <div class="sb-item">
        <div class="sb-title">
          <span class="sb-title-title">使用e旗学平台进行考试</span>
        </div>
      </div>
      <hr />
      <div class="sb-item">
        <div class="sb-title">
          <span class="sb-title-title">每次得分</span>
          <span class="sb-title-content">
            <span class="sb-socre-input{">
              <a-input-number class="sb-socre-input"
                              :min="1"
                              :max="100"
                              v-model="model.everyTimesGetScore" />
            </span>
            分
          </span>
        </div>
      </div>
      <div class="sb-item">
        <div class="sb-title">
          <span class="sb-title-title">每次加分次数上限</span>
          <span class="sb-title-content">
            <span class="sb-socre-input">
              <a-input-number class="sb-socre-input"
                              :min="1"
                              :max="100"
                              v-model="model.maxNum" />
            </span>
            次
          </span>
        </div>
      </div>
      <div class="sb-item-btn">
        <a-button type="primary"
                  @click="save"> 提交 </a-button>
      </div>
    </div>
    <div class="loading">
      <Loading v-show="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { postConfigSave, getConfigShow } from '@/api/training/ScoreRule.js';
import Loading from '../components/Loading.vue';
let model = {
  internalTrainPlan: '',
  siteImages: '',
  subjectMinPassRate: '',
  everyTimesGetScore: '',
  maxNum: '',
};

export default {
  name: 'SelfBuilt',
  data() {
    return {
      model,
      isLoading: false,
      listData: [],
    };
  },
  components: {
    Loading,
  },
  methods: {
    getPage(page) {
      console.log(page);
    },
    getData: function () {
      this.isLoading = true;
      getConfigShow({ type: 3 })
        .then((res) => {
          if (res.error_code >= 0) {
            this.listData = res.data.default;
            for (let index in res.data.default) {
              let item = res.data.default[index];
              if (this.model[item.key] !== undefined) {
                this.model[item.key] = item.value;
              }
            }
          } else {
            this.$message.error('获取数据失败!请稍后重试');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    save() {
      // for (let key in this.model) {
      //   let item = this.model[key];
      //   if (item === '') {
      //     this.$message.error('');
      //   }
      // }
      if (!this.model.internalTrainPlan) {
        this.$message.info('请填写内训计划');
        return;
      }
      if (!this.model.siteImages) {
        this.$message.info('请填写现场照片');
        return;
      }
      if (!this.model.subjectMinPassRate) {
        this.$message.info('请填写总体考试通过率要求');
        return;
      }
      if (!this.model.everyTimesGetScore) {
        this.$message.info('请填写每次得分');
        return;
      }
      if (!this.model.maxNum) {
        this.$message.info('请填写每次加分次数上限');
        return;
      }

      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      postConfigSave(this.model)
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.selfbuilt {
  // width: 100%;
  float: left;
  position: relative;
}
.sb-title {
  display: flex;
  // width: 100%;
}
.sb-list {
  display: block;
  // width: 100%;
}
.sb-item {
  display: block;
  // width: 100%;
  height: 40px;
  line-height: 40px;
}
.sb-title-title {
  flex: 1;
  text-align: left;
  min-width: 200px;
  max-width: 200px;
}
.sb-title-content {
  flex: 3;
  text-align: left;
}
.title-content-title {
  margin-left: 300px;
}
.sb-socre-input {
  width: 120px;
}
.sb-item-btn {
  text-align: center;
}
.tc-paging {
  display: block;
  text-align: center;
  margin-top: 10px;
}
</style>
