<template>
  <div class="full-size">
    <CommonTitle :titles="titles"/>

    <collapse-training-info :id="id"/>

    <div class="flex flex-end ph-20 mt-10 mb-10">
      <a-button type="primary" class="pos-relative ph-20"
                @click="batchDownload">
        <span>批量下载</span>
      </a-button>
      <a-button class="ml-30" @click="back">返回</a-button>
    </div>

    <div class="flex pd-card">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               :row-selection="rowSelection"
               row-key="id" class="table small-cell-table">

        <template slot="files" slot-scope="text, record">
          <template v-if="record.org_upfile_resource_info && record.org_upfile_resource_info.length">
            <template v-if="record.org_upfile_resource_info[0]
            && record.org_upfile_resource_info[0].upfile_form">
              {{ record.org_upfile_resource_info[0].title }}
            </template>
            <template v-else-if="record.type === 1">
              <img v-for="(item, idx) in record.org_upfile_resource_info"
                   :key="idx"
                   :src="item.url"
                   alt=""
                   class="thumb-img"
                   @click="previewImg(item.url)">
            </template>
            <template v-else>{{ record.org_upfile_title }}</template>
          </template>
        </template>

        <template slot="action" slot-scope="text, record">
          <a-button v-if="record.is_upload" type="link" @click="reject(record)">打回</a-button>

          <template v-if="record.org_upfile_resource_info && record.org_upfile_resource_info.length">
            <a-button v-if="record.type === 3" type="link" @click="previewForm(record)">预览</a-button>
            <template v-else>
              <a-button v-if="record.type === 2" type="link" @click="previewFile(record)">预览</a-button>
              <a-button type="link" @click="download(record)">下载</a-button>
            </template>
          </template>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <image-viewer ref="imageViewerRef" :url="imgUrl"/>
    <form-modal ref="formModalRef"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/utils/Common';
// import downloadFile from '@/utils/JsDownload';
import {
  getUnifiedTrainingDetailSubmittedMaterial,
  rejectUnifiedTrainingDetailSubmittedMaterial,
} from '@/api/training/Index';

import CommonTitle from '@components/CommonTitle.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';
import CollapseTrainingInfo from '../../components/CollapseTrainingInfo.vue';
import ImageViewer from '@/components/ImageViewer.vue';
import FormModal from './FormModal';

export default {
  name: 'SubmittedMaterial',
  components: {
    CommonTitle,
    Pagination,
    CollapseTrainingInfo,
    ImageViewer,
    FormModal,
  },
  computed: {
    ...mapState({}),
    rowSelection() {
      return {
        selectedRowKeys: (this.selectedRows || []).map((i) => i.id),
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.type === 3 || !record.org_upfile_resource_info?.length,
          },
        }),
      };
    },
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '内训列表' }, { title: '内训详情' }, { title: '提交材料' }],

      id: 0,
      orgIds: '',

      imgUrl: '',
      imgUrls: [],

      downloading: false,
      loading: false,
      columns: [
        {
          width: '80px',
          align: 'center',
          dataIndex: 'title',
          key: 'title',
          title: '回传项',
        },
        {
          width: '220px',
          align: 'center',
          dataIndex: 'files',
          key: 'files',
          title: '回传文件',
          scopedSlots: { customRender: 'files' },
        },
        {
          width: '100px',
          align: 'center',
          dataIndex: 'created_at_str',
          key: 'created_at_str',
          title: '回传时间',
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      pagination: getPagination(),
      selectedRows: [],
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;
    this.orgIds = this.$route.query?.o_id || '';

    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },

    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize);

      const data = await getUnifiedTrainingDetailSubmittedMaterial(this.id, params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        org_id: this.orgIds,
        page: page,
        per_page: pageSize,
      };
      delete params.date;

      return params;
    },

    reject(record) {
      this.$confirm({
        content: '确认打回该材料？',
        onOk: () => this.confirmReject(record),
      });
    },
    async confirmReject(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await rejectUnifiedTrainingDetailSubmittedMaterial(record.id, {
        training_id: record.training_id,
        org_id: record.org_id,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '打回失败');
        return;
      }
      this.$message.success(data?.message || '打回成功');
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    previewForm(record) {
      if (record.type === 3) {
        if (this.$refs.formModalRef?.show && record.org_upfile_resource_info?.length) {
          this.$refs.formModalRef.show({
            title: record.org_upfile_resource_info[0]?.title,
            list: record.org_upfile_resource_info[0]?.upfile_form,
          });
        }
      }
    },
    previewFile(record) {
      if (record.org_upfile_resource_info[0]?.type === 3 || record.org_upfile_resource_info[0]?.type === 6) {
        window.open(record.org_upfile_resource_info[0]?.url, '_blank');
      } else {
        this.download(record);
      }
    },
    download(record) {
      const list = record?.org_upfile_resource_info || [];
      // list.forEach((i) => {
      //   const url = i.pc_resource_url + (i.pc_resource_url.indexOf('?') > -1 ? ('&title=' + i.down_title) : ('?is_download=1&title=' + i.down_title));
      //   // Common.downloadFile(url);
      //   downloadFile(url, i.down_title);
      // });

      this.downloadItems(list);
    },
    batchDownload() {
      if (!this.selectedRows?.length) {
        this.$message.info('请选择要下载的文件');
        return;
      }
      if (this.selectedRows.every((i) => !i.org_upfile_resource_info?.length)) {
        this.$message.info('所选回传项没有回传文件');
        return;
      }

      const list = [];
      this.selectedRows.forEach((i) => {
        list.push(...(i.org_upfile_resource_info || []).map((item) => ({ ...item, rowId: i.id })));
      });
      // list.forEach((i) => {
      //   const url = i.pc_resource_url + (i.pc_resource_url.indexOf('?') > -1 ? ('&title=' + i.down_title) : ('?is_download=1&title=' + i.down_title));
      //   // window.open(url, '_blank');
      //   downloadFile(url, i.down_title);
      // });
      this.downloadItems(list).then((data) => {
        if (data.every((i) => i.status === 'fulfilled')) {
          this.selectedRows = [];
        } else {
          const rows = [];
          data.forEach((i, idx) => {
            if (i.status !== 'fulfilled') {
              const index = this.selectedRows.findIndex((row) => row.id === list[idx].rowId);
              rows.push(this.selectedRows[index]);
            }
          });
          this.selectedRows = rows;
        }
      });
    },
    downloadItems(list) {
      return Promise.allSettled(list.map((i) => {
        const url = i.pc_resource_url + (i.pc_resource_url.indexOf('?') > -1 ? ('&title=' + i.down_title) : ('?is_download=1&title=' + i.down_title));
        // return Common.downloadFile(url, i.down_title);
        window.open(url);
      })).then((data) => {
        if (data.every((i) => i.status === 'fulfilled')) {
          this.$message.success('下载成功');
        } else if (data.some((i) => i.status === 'fulfilled')) {
          this.$message.success('下载完成，有部分文件下载失败，请重试');
        } else {
          this.$message.error('下载失败，请重试');
        }
        return data;
      }).catch((err) => {
        this.$message.error('下载失败');
        return err;
      });
    },

    previewImg(url) {
      this.imgUrl = url;

      if (this.$refs.imageViewerRef?.show) {
        this.$refs.imageViewerRef.show();
      }
    },

  },
};
</script>

<style scoped lang="scss">
.thumb-img {
  width: 80px;
  margin: 5px;
  cursor: pointer;
}
</style>
