<template>
  <div class="re-renders">
    <a-collapse default-active-key="1"
                :bordered="false">
      <template #expandIcon="props">
        <a-icon type="caret-right"
                :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel key="1"
                        :header="trainingTitle">
        <p>内训主题：{{ trainingTitle }}</p>
        <p>内训类型：{{ trainingType }}</p>
        <p class="mb-0">内训时间：{{ trainingDate }}</p>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>


<script>
import { getUnifiedTrainingBasic } from '@/api/training/Index'

export default {
  data() {
    return {
      trainingTitle: '',
      trainingType: '',
      trainingDate: '',
    }
  },
  props: {
    id: { type: Number, default: () => 0 },
  },
  created() {
    // this.id = this.$route.query?.id || 0
    this.getUnifiedTrainingBasic()
  },
  beforeDestroy() {
    this.$store.commit('Training/updateUnifiedDetailOrgIds', '')
  },
  methods: {
    async getUnifiedTrainingBasic() {
      const data = await getUnifiedTrainingBasic(this.id)
      if (!data?.error_code) {
        const result = data?.data || {}
        this.trainingTitle = result.title || ''
        this.trainingType = result.type_title || ''
        this.trainingDate = `${result.starttime_1} 至 ${result.endtime_1}`

      
      }
    },
  },
}
</script>
