<template>
  <create-training-material training-type="1"/>
</template>

<script>
import CreateTrainingMaterial from '../components/CreateTrainingMaterial.vue';

export default {
  name: 'Material',
  components: { CreateTrainingMaterial },
};
</script>

<style scoped lang="scss">

</style>
