
<template>
  <div class="">
    <div class="header-breadcrumb">
      <CommonTitle :titles="titles"> </CommonTitle>
      <router-view></router-view>
      <!-- <TabDetailPage :title="menu_title" :menus="menus"></TabDetailPage> -->
      <!-- <div class="menu-body">
        <router-view />
      </div> -->
    </div>
  </div>
</template>

<script>
import CommonTitle from "@components/CommonTitle";

//import { postConfigSave } from "@/api/training/ScoreRule.js";

// let menu_title = [
//   {
//     id: "1",
//     title: "计划内训配置",
//     path: "/index/training/scoreRule/unified/",
//   },
//   {
//     id: "2",
//     title: "自发组织内训",
//     path: "/index/training/scoreRule/selfBuilt",
//   },
// ];
// let menus = [
//   {
//     id: "1",
//     title: "计划内训配置",
//     path: "/index/training/scoreRule/unified/",
//   },
//   {
//     id: "2",
//     title: "自发组织内训",
//     path: "/index/training/scoreRule/selfBuilt",
//   },
// ];



export default {
  name: "SelfBuilt",
  data () {
    return {
      current: ["JiHuaNeiXun"],
    };
  },
  computed: {
    titles () {
      return [
        { title: "内训管理" },
        { title: "内训列表" },
        { title: "评分规则管理" },
        { title: this.getRouteTitle() }
      ];
    }
  },
  components: {
    CommonTitle,
  },
  methods: {
    getRouteTitle () {
      let result = "";
      if (this.$route.path === '/index/training/scoreRule/unified/add' || this.$route.path === '/index/training/scoreRule/unified/label') {
        result = "内训计划配置";
      }
      if (this.$route.path === '/index/training/scoreRule/selfBuilt') {
        result = "自发组织配置";
      }
      return result;
    }
  },
  created: function () { },
};
</script>

<style scoped lang="scss">
.menu {
  background: #ffffff;
  width: 100%;
  border-bottom: 1.5px solid;
  margin-left: 20px;
}
.menu-body {
  margin: 20px;
}
.selfbuilt {
  width: 1100px;
  float: left;
  margin: 20px;
}
</style>
