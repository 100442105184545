<template>
  <div class="full-size">
    <CommonTitle :titles="titles"/>
    <div class="ph-20">
      <collapse-training-info :id.sync="this.id"/>
    </div>
    <div class="flex pd-card">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               :rowKey="(record) => record.id"
               class="table small-cell-table">
        <template slot="status_str" slot-scope="text, record">
          <span :style="{color: record.color}">{{ text }}</span>
        </template>

        <template slot="action"
                  slot-scope="text, record"
                  v-if="record.type !== 0">
          <a-button type="link"
                    :disabled="record.url === '' ||  record.upfile_id <= 0"
                    @click="preview(record)">预览
          </a-button>
          <a-button type="link"
                    :disabled="record.url === '' ||  record.upfile_id <=0"
                    @click="downloadItem(record)">下载
          </a-button>
        </template>
      </a-table>
    </div>

    <div class="flex flex-start pd-card mt-30">
      <a-button @click="back">返回</a-button>
    </div>

  </div>
</template>

<script>
import { getSelfBuildScoreDetail } from '@/api/training/Index';

import CommonTitle from '@components/CommonTitle';
import downloadFile from '@/utils/JsDownload';

import CollapseTrainingInfo from '../components/CollapseTrainingInfo.vue';

export default {
  name: 'Score',
  components: {
    CommonTitle,
    CollapseTrainingInfo,
  },
  data() {
    return {
      id: parseInt(this.$route.query.id),
      titles: [
        { title: '内训管理' },
        { title: '自建内训管理' },
        { title: '评分明细' },
      ],

      downloading: false,
      loading: false,
      columns: [
        {
          width: '100px',
          align: 'center',
          dataIndex: 'title',
          key: 'title',
          title: '考评项',
        },
        {
          width: '180px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'status_str',
          key: 'status_str',
          title: '状态',
          scopedSlots: { customRender: 'status_str' },
        },
        {
          width: '100px',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },
    onSearch() {
      this.getData(1, 10);
    },
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        training_id: this.id,
      };
      const data = await getSelfBuildScoreDetail(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      let completeness = data.data.completeness;
      this.data = [];

      this.data.push(
        {
          id: 1,
          title: '内训计划',
          status_str: completeness?.siteForm === 0 ? '未上传' : '已上传',
          color: completeness?.siteForm ? '#20d420' : '#FF0000',
          url: data?.data?.upfile?.file[0]?.upfile_log_url || '',
          upfile_id: data?.data?.upfile?.file[0]?.upfile_id ?? 0,
          type: 1,
        },
        {
          id: 2,
          title: '现场照片',
          status_str: completeness?.siteImage === 0 ? '未上传' : '已上传',
          color: completeness?.siteImage ? '#20d420' : '#FF0000',
          url: data?.data?.upfile?.image[0]?.upfile_log_url || '',
          upfile_id: data?.data?.upfile?.image[0]?.upfile_id ?? 0,
          type: 2,
        },
        {
          id: 3,
          title: '总体考试通过率',
          status_str: `${completeness?.pass_lv}%`,
          url: '',
          type: 0,
          upfile_id: 0,
        },
        {
          id: 4,
          title: '使用e旗学平台课件',
          status_str: completeness?.used_task === 0 ? '未达成' : '已达成',
          color: completeness?.used_task ? '#20d420' : '#FF0000',
          url: '',
          type: 0,
          upfile_id: 0,
        },
        {
          id: 5,
          title: '使用e旗学进行考试',
          status_str: completeness?.used_exam === 0 ? '未达成' : '已达成',
          color: completeness?.used_exam ? '#20d420' : '#FF0000',
          url: '',
          type: 0,
          upfile_id: 0,
        },
      );
    },

    preview(record) {
      window.open(record.url, '_blank');
    },
    downloadItem(record) {
      /(\.[^.]+)$/g.test(record.url);
      const fileName = record.title + RegExp.$1;

      downloadFile(record.url, fileName);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
