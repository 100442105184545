<template>
  <div class="full-size training-index">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Index',
};
</script>

<style scoped lang="scss">
.training-index {
  overflow-y: auto;
}
</style>
