<template>
  <div class="form-list">
    <div class="common-title">
      <CommonTitle :titles="titles"> </CommonTitle>
      <div class="common-title-btn">
        <a-button type="primary"
                  @click="hrefDetail(0)"> 创建表单 </a-button>
      </div>
    </div>

    <div class="survey-ant-table">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="isLoading"
               rowKey="id"
               bordered>
        <a slot="title1"
           slot-scope="text, item"
           @click="hrefDetail(item.id)">{{
          text
        }}</a>
        <span slot="is_status"
              slot-scope="text, item">
          <span>{{ item.status_str }}</span>
        </span>
        <span slot="active"
              slot-scope="text, item"
              class="survey-aciion">
          <sapn v-if="item.id <= 0"></sapn>
          <a @click="hrefDetail(item.id)"
             v-if="item.is_used === 0"
             :disabled="isdisabled"> 编辑</a>
          <a v-else
             @click="hrefDetail(item.id)">查看</a>
          <span v-if="item.is_used ===0">
            <a @click="enableFromById(item)"
               :disabled="item.is_used === 1"
               v-if="item.is_status === 0"> 启用</a>
            <a @click="enableFromById(item)"
               :disabled="item.is_used === 1"
               v-if="item.is_status === 1">
              停用</a>
          </span>
          <span v-if="item.is_used ===0">
            <a @click="deleteFromById(item.id)"
               :disabled="item.is_used === 1"> 删除</a>
          </span>

          <a @click="downloadFrom(item.id,item.title)">下载</a>
        </span>
        <!-- <template slot="footer">
          <Pagination
            v-if="pagination && pagination.total"
            :pagination="pagination"
            class="pagination"
            @change="onPageChange"
            @showSizeChange="onSizeChange"
          ></Pagination>
        </template> -->
      </a-table>
    </div>
    <!-- <div class="loading">
      <LoadingContent v-show="isLoading"></LoadingContent>
      <Loading v-show="isLoading"></Loading>
    </div> -->
    <a-modal title="提示"
             :visible="show_model.visible"
             :confirm-loading="show_model.confirmLoading"
             @ok="showModelOk"
             @cancel="showModelCancel">
      <div v-html="show_model.model_text"></div>
    </a-modal>
  </div>
</template>

<script>
// import Loading from "../components/Loading.vue";
// import LoadingContent from "@/components/LoadingContent.vue";
import CommonTitle from "@components/CommonTitle";
// import Pagination, { getPagination } from "@components/Pagination.vue";
import {
  getFormShowList,
  deleteForm,
  enableFromSaveUpdate,
  getExportFormByFrom
} from "@/api/training/Form.js";

let titles = [{ title: "内训管理" }, { title: "内训表单管理" }];

const columns = [
  {
    title: "表单名称",
    dataIndex: "title",
    key: "title",
    scopedSlots: { customRender: "title1" },
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "created_at_str",
    key: "created_at_str",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "is_status",
    key: "is_status",
    scopedSlots: { customRender: "is_status" },
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "active",
    scopedSlots: { customRender: "active" },
    align: "center",
  },
];
let show_model = {
  visible: false,
  confirm_loading: false,
  model_text: "",
  id: 0,
  isOk: 0,
};
export default {
  name: "List",
  data () {
    return {
      titles,
      columns,
      data: [],
      isLoading: true,
      show_model,
      isdisabled: false,
    };
  },
  components: {
    CommonTitle,
  },
  methods: {
    getList: function () {
      this.isLoading = true;
      getFormShowList()
        .then((res) => {
          if (res.error_code === 0) {
            console.log(res.data);
            this.data = res.data;
            this.pagination = {
              current_page: res.meta.pagination.current_page,
              total: res.meta.pagination.total,
              per_page: 50,
            };
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getPage (pagination) {
      if (this.pagination.current_page !== pagination) {
        this.pagination.current_page = pagination;

        this.getList();
      }
    },
    hrefDetail (id) {
      this.$router.push({
        path: "Detail",
        query: {
          id: id,
        },
      });
    },
    enableFromById (item) {
      let text = `确认要${item.is_status === 1 ? "停用" : "启用"}当前表单吗?`;
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          this.isLoading = true;
          console.log(item.id);
          enableFromSaveUpdate(item.id)
            .then((res) => {
              this.$message.success(res.message);
              this.clearSearch();
              this.getList();
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
      });
    },
    deleteFromById (id) {
      let text = "确认要删除当前表单吗?";
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          this.isLoading = true;
          deleteForm(id)
            .then((res) => {
              if (res.error_code === 0) {
                this.$message.success(`删除成功`);
                this.clearSearch();
                this.getList();
              } else {
                this.$message.success("删除失败", res.message);
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
      });
    },
    showModelOk () {
      this.show_model.visible = false;
      this.show_model.isOk = 1;
    },
    showModelCancel () {
      this.show_model.visible = false;
      this.show_model.isOk = 2;
    },
    clearSearch () {
      this.pagination = {
        current_page: 1,
        per_page: 50,
        total: 0,
      };
    },

    saveShowModel: function () {
      let that = this;
      return new Promise(function (resolve) {
        let interval = setInterval(function () {
          if (that.show_model.isOk !== 0) {
            clearInterval(interval);
            if (that.show_model.isOk === 1) {
              resolve(1);
            } else {
              resolve(2);
            }
          }
        }, 500);
      });
    },
    downloadFrom (id, title) {
      let params = { form_id: id };
      getExportFormByFrom(params, `${title}.xlsx`).then(() => {
        this.$message.success("下载成功");
      }, (res) => {
        this.$message.error("下载失败", res.message);
      })
    }
    // onPageChange(page, pageSize) {
    //   this.getList(page, pageSize);
    // },
    // onSizeChange(current, size) {
    //   this.getList(1, size);
    // },
  },
  created () {
    this.getList(1, 10);
  },
};
</script>

<style scoped lang="scss">
.form-list {
  position: relative;
}
.survey-ant-table {
  margin: 30px 20px 0 20px;
}
.survey-aciion a {
  margin: 5px;
}
.common-title {
  // width: 100%;
  position: relative;
}
.common-title-btn {
  position: absolute;
  right: 50px;
  top: 20px;
}
.tc-paging {
  display: block;
  text-align: center;
  margin-top: 10px;
}
</style>
