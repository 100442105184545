<template>
  <div class="full-size auto-y">
    <CommonTitle :titles="titles"/>

    <div class="pd-card">
      <a-form-model layout="inline">
        <a-form-model-item label="内训主题">
          <a-input v-model="form.training_title"
                   placeholder="请输入关键字"/>
        </a-form-model-item>
        <a-form-model-item label="起止日期">
          <a-range-picker v-model="form.date"/>
        </a-form-model-item>
        <a-form-model-item label="参与组织机构">
          <select-org v-model="form.org_ids_str"
                      :show-label="false"/>
        </a-form-model-item>
        <a-form-model-item label="结算状态">
          <select-settlement-status v-model="form.is_settlement"></select-settlement-status>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" class="mr-60" @click="onSearch()">查询</a-button>
          <a-button @click="onReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="flex pd-card">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :loading="loading"
        bordered
        row-key="id"
        class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="viewStudentDetail(record)">学员详情</a-button>
          <a-button v-if="!record.is_settlement" type="link" @click="settleTraining(record)">结算评分</a-button>
          <a-button v-else type="link" @click="viewScoreDetail(record)">评分明细</a-button>
        </template>

        <template slot="footer">
          <Pagination
            v-if="pagination && pagination.total"
            :pagination="pagination"
            class="pagination"
            @change="onPageChange"
            @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <full-content-modal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import {
  getSelfBuiltTrainingList,
  saveSettlement,
} from '@/api/training/Index';
import Common from '@/utils/Common';

import SelectOrg from '@/components/SelectOrg';
import CommonTitle from '@components/CommonTitle';
import Pagination, { getPagination } from '@components/Pagination';
import FullContentModal from '../components/FullContentModal';
import SelectSettlementStatus from '../components/SelectSettlementStatus.vue';
import { Modal } from 'ant-design-vue';

export default {
  name: 'List',
  components: {
    CommonTitle,
    Pagination,
    SelectOrg,
    FullContentModal,
    SelectSettlementStatus,
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '自建内训管理' }],

      downloading: false,
      loading: false,
      columns: [
        {
          width: '120px',
          align: 'center',
          dataIndex: 'title',
          key: 'title',
          title: '内训主题',
        },
        {
          width: '60px',
          align: 'center',
          dataIndex: 'big_org_title',
          key: 'big_org_title',
          title: '大区',
        },
        {
          width: '180px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'org_title',
          key: 'org_title',
          title: '店名',
          customCell: (record) => {
            return {
              style: {
                width: '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () =>
                  this.showFullContent(record['org_title'], '店名详情'),
              },
            };
          },
        },
        {
          width: '180px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'training_post_title',
          key: 'training_post_title',
          title: '参与岗位',
          customCell: (record) => {
            return {
              style: {
                width: '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () =>
                  this.showFullContent(
                    record['training_post_title'],
                    '参与岗位详情',
                  ),
              },
            };
          },
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'is_settlement_title',
          key: 'is_settlement_title',
          title: '结算状态',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'starttime_2',
          key: 'starttime_2',
          title: '开始时间',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'endtime_2',
          key: 'endtime_2',
          title: '结束时间',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'trainer_truename',
          key: 'trainer_truename',
          title: '内训师',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'trainer_username',
          key: 'trainer_username',
          title: '电话',
        },
        {
          width: '40px',
          align: 'center',
          dataIndex: 'score',
          key: 'score',
          title: '得分',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'budget_yuan',
          key: 'budget_yuan',
          title: '预算',
        },
        {
          width: '150px',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      pagination: getPagination(),

      form: {
        training_title: '',
        date: [undefined, undefined],
        org_ids_str: '',
        is_settlement: -1,
      },
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onReset() {
      this.form = {
        training_title: '',
        date: [undefined, undefined],
        org_ids_str: '',
      };

      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (
        this.data?.length - 1 <= 0 &&
        this.pagination.current * this.pagination.pageSize >=
        this.pagination.total
      ) {
        this.getData(
          this.pagination.current > 1 ? this.pagination.current - 1 : 1,
          this.pagination.pageSize,
        );
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      const params = this.getParams(page, pageSize);

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getSelfBuiltTrainingList(params).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        ...this.form,
        start_day: this.form.date[0]?.format('YYYY-MM-DD') || '',
        end_day: this.form.date[1]?.format('YYYY-MM-DD') || '',
        page: page,
        per_page: pageSize,
      };
      delete params.date;

      return params;
    },
    exportExcel() {},

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({
          content: content.split(','),
          title,
        });
      }
    },
    // 单个结算
    settleTraining(record) {
      Modal.confirm({
        title: '提示',
        maskClosable: true,
        content: '是否确定结算？',
        onOk: () => this.settle([record.id]),
      });
    },
    async settle(ids) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await saveSettlement({
        training_ids: (ids || []).join(','),
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '结算失败');
        return;
      }
      this.$message.success(data?.message || '结算成功');
      this.onRefreshPage();
    },

    viewStudentDetail(record) {
      this.$router.push({
        path: '/index/training/selfBuilt/student',
        query: {
          id: record.id,
          orgId: record.org_id,
        },
      });
    },
    viewScoreDetail(record) {
      console.log(record);
      this.$router.push({
        path: '/index/training/selfBuilt/score',
        query: {
          id: record.id,
          org_id: record.org_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
