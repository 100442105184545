import request from '@/utils/Request';

// 调研---列表
export function getResearchList(params) {
    return request({
        url: `/internaltraining/admin/research`,
        method: 'get',
        params,
    });
}
// 调研---新增
export function postResearchCreate(data) {
    return request({
        url: `/internaltraining/admin/research/create`,
        method: 'post',
        data
    });
}
// 调研---编辑
export function postResearchUpdate(id, data) {
    return request({
        url: `/internaltraining/admin/research/update/${id}`,
        method: 'post',
        data

    });
}

// 调研---删除
export function deleteResearch(id) {
    return request({
        url: `/internaltraining/admin/research/delete/${id}`,
        method: 'delete',
    });
}
// 调研---下架
export function postResearchShuton(id) {
    return request({
        url: `/internaltraining/admin/research/shuton/${id}`,
        method: 'post',
    });
}


// 调研详细
export function getResearchDetail(id) {
    return request({
        url: `/internaltraining/admin/research/${id}`,
        method: 'get',
    });
}
