<template>
  <div class="full-size">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Index',
  beforeDestroy() {
    this.$store.commit('Training/updateScoreMonth', []);
  },
};
</script>

<style scoped lang="scss">

</style>
