<template>
  <div class="full-size">
    <CommonTitle :titles="titles"></CommonTitle>

    <a-form-model
      ref="modelRef"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item label="调研名称" prop="title">
        <a-input v-model="form.title" :disabled="isSwitch" placeholder="请输入调研名称"/>
      </a-form-model-item>
      <a-form-model-item label="积分奖励" prop="score">
        <a-input-number v-model="form.score" :min="0"
                        :disabled="isSwitch"
                        :formatter="value => `${value}`.replace(/\.*/g, '')"
                        :parser="value => `${value}`.replace(/\.*/g, '')"/>
        <span class="ml-5">分</span>
      </a-form-model-item>
      <a-form-model-item label="链接地址" prop="url">
        <a-input v-model="form.url" :disabled="isSwitch" placeholder="请输入链接地址"/>
      </a-form-model-item>
      <a-form-model-item label="推送岗位" prop="post_ids">
        <select-job-grouped v-model="form.post_ids" :disabled="isSwitch"/>
      </a-form-model-item>
      <a-form-model-item label="推送组织机构" prop="org_ids" class="org-wrapper">
        <select-org v-model="form.org_ids" :show-label="false" :disabled="isSwitch"/>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
        <a-button v-if="!isSwitch" type="primary" @click="save">提交</a-button>
        <a-button @click="back">返回</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  getResearchDetail,
  postResearchCreate,
  postResearchUpdate,
} from '@/api/training/Survey';

import CommonTitle from '@components/CommonTitle.vue';
import SelectJobGrouped from '@components/SelectJobGrouped.vue';
import SelectOrg from '@/components/SelectOrg.vue';

const titles = [
  { title: '内训管理' },
  { title: '调研列表' },
  { title: '调研配置' },
];
export default {
  name: 'Verify',
  components: {
    CommonTitle,
    SelectJobGrouped,
    SelectOrg,
  },
  data() {
    return {
      titles,
      loading: false,
      id: 0,
      isSwitch: true,

      labelCol: { span: 4 },
      wrapperCol: { span: 20 },

      form: {
        title: '',
        score: 0,
        url: '',
        post_ids: '',
        org_ids: '',
      },
      rules: {
        title: [
          { required: true, trigger: 'blur', message: '请填写调研名称', whitespace: true },
        ],
        score: [],
        url: [
          { required: true, trigger: 'blur', message: '请填写链接地址', whitespace: true },
        ],
        post_ids: [
          { required: true, trigger: 'change', message: '请选择推送岗位' },
        ],
        org_ids: [
          { required: true, trigger: 'change', message: '请选择组织机构' },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.query.id;

    if (!this.id) {
      this.isSwitch = false;
    }

    this.getList();
  },
  methods: {
    back() {
      this.$router.back();
    },
    async getList() {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const data = await getResearchDetail(this.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取详情失败，请刷新重试');
        return;
      }
      const result = data?.data || {};
      this.isSwitch = !!result.is_switch;
      this.form = {
        title: result.title || '',
        score: result.score || 0,
        url: result.url || '',
        post_ids: (result.post_ids || []).join(','),
        org_ids: (result.org_ids || []).join(','),
      };
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.modelRef.validate((validated) => {
          if (validated) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    save() {
      this.validate().then(() => {
        this.update();
      });
    },
    async update() {
      if (this.isSwitch || this.loading) {
        return;
      }
      this.loading = true;

      const params = { ...this.form };

      const data = await (this.id && this.id > 0
          ? postResearchUpdate(this.id, params)
          : postResearchCreate(params)
      ).finally(() => (this.loading = false));
      if (!data || data.error_code) {
        this.$message.error(data?.message || `保存失败`);
        return;
      }
      this.$message.success(data?.message || `保存成功`);
      this.back();
    },
  },
};
</script>

<style scoped lang="scss">
.pd-content {
  margin-top: 30px;
  display: flex;
  width: 100%;
}

.pd-content-list {
  display: block;
  width: 100%;
}

.pd-content-item {
  display: flex;
  width: 100%;
}

.pd-content-left {
  flex: 1;
  min-width: 150px;
  text-align: right;
}

.pd-content-right {
  flex: 8;
  margin-left: 20px;
}

.pd-content-right div span {
  margin: 10px;
}

.pd-content-tip {
  margin-top: 30px;
  display: block;
  width: 100%;
}

.pd-content-btn {
  margin-top: 30px;
  display: block;
  width: 100%;
  text-align: center;
}

.ant-input {
  width: 80%;
}

.sd-ant-input {
  width: 300px;
  min-width: 300px;
}

.survey-detail {
  position: relative;
}

.org-wrapper {
  ::v-deep .ant-form-explain {
    margin-top: 8px;
  }
}
</style>
