<template>
  <a-select v-model="selectedId"
            :disabled="disabled" :placeholder="placeholder"
            class="select"
            @change="select">
    <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
  </a-select>
</template>

<script>

const options = [
  { id: -1, name: '不限' },
  { id: 0, name: '未结算' },
  { id: 1, name: '结算中' },
  { id: 2, name: '已结算' },
];

export default {
  name: 'SelectSettlementStatus',
  model: {
    prop: 'id',
    event: 'select-id',
  },
  props: {
    id: { type: Number, default: -1 },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '请选择结算状态' },
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        this.selectedId = newId;
      },
    },
  },
  data() {
    return {
      options,

      selectedId: this.id,
    };
  },
  methods: {
    select(id) {
      this.$emit('select-id', id);
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 150px;
}
</style>
