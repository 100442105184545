<template>
  <div class="full-size auto-y">
    <CommonTitle :titles="titles"/>

    <div class="pd-card">
      <a-form-model layout="inline">
        <a-form-model-item label="查询时间范围">
          <a-range-picker :value="form.date" :mode="['month', 'month']" format="YYYY-MM"
                          @change="dateChange" @panelChange="dateChange"></a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="参与组织机构">
          <select-org v-model="form.org_ids" :show-label="false"
                      mode="single" :only-leaf="true"></select-org>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" class="mr-60" @click="onSearch()">查询</a-button>
          <a-button class="mr-30" @click="onReset">重置</a-button>
          <a-button @click="back">返回</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="flex flex-end ph-20 ant-empty">
      <a-button type="primary" :loading="downloading" @click="exportExcel()">导出</a-button>
    </div>

    <div class="flex flex-start pd-card">
      共享内容至公开云累计得分 {{ totalCloudScore }} 分（包含贡献奖励及人工评分）
    </div>

    <div class="flex pd-card">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">

        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="viewDetail(record)">查询明细</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <full-content-modal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import moment from 'moment';
import {
  getScoreTrainingList,
  getScoreTrainingListDownload,
} from '@/api/training/Index';
import Common from '@/utils/Common';

import SelectOrg from '@/components/SelectOrg.vue';
import CommonTitle from '@components/CommonTitle.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';
import FullContentModal from '../components/FullContentModal.vue';


export default {
  name: 'ScoreTrainingList',
  components: {
    CommonTitle,
    Pagination,
    SelectOrg,
    FullContentModal,
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '内训计划列表' }, { title: '内训评分' }],

      downloading: false,
      loading: false,
      id: undefined,
      columns: [
        {
          width: '100px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'title',
          key: 'title',
          title: '内训主题',
          customCell: (record) => {
            return {
              style: {
                width: '100px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record['title'], '大区详情'),
              },
            };
          },
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'type_str',
          key: 'type_str',
          title: '类型',
        },
        {
          width: '180px',
          align: 'center',
          dataIndex: 'start_end_str',
          key: 'start_end_str',
          title: '起止时间',
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'score',
          key: 'score',
          title: '总评分',
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      pagination: getPagination(),
      totalCloudScore: 0,

      form: {
        org_ids: '',
        date: [moment().startOf('months'), moment()],
      },
    };
  },
  created() {
    this.id = this.$route.query.id || undefined;
    this.form.org_ids = this.$route.query.o_id || this.form.org_ids || '';

    const startMonth = this.$route.query.s_month || '';
    const endMonth = this.$route.query.e_month || '';

    if (startMonth) {
      this.form.date[0] = moment(startMonth, 'YYYY-MM');
    }
    if (endMonth) {
      this.form.date[1] = moment(endMonth, 'YYYY-MM');
    }

    this.onSearch();
  },
  methods: {
    dateChange(value) {
      this.form.date = value;
    },
    back() {
      this.$router.back();
    },
    onReset() {
      this.form = {
        org_ids: '',
        date: [moment().startOf('months'), moment()],
      };

      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      const params = this.getParams(page, pageSize);

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getScoreTrainingList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = (data?.data || []).map((i, idx) => ({
        ...i,
        id: i.id || (idx + (i.created_at || '')),
      }));

      this.totalCloudScore = data?.meta?.share_total_score || 0;
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, down) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        org_ids: this.form.org_ids,
        start_month: this.form.date[0]?.format('YYYY-MM-DD') || '',
        end_month: this.form.date[1]?.format('YYYY-MM-DD') || '',
        page: page,
        per_page: pageSize,
      };
      if (down) {
        params.down = 1;
      }

      return params;
    },
    async exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = this.getParams(this.pagination.current, this.pagination.pageSize, 1);
      const data = await getScoreTrainingListDownload(this.id, params).finally(() => this.downloading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `导出失败`);
        return;
      }
      this.$message.success(data?.message || '导出成功');
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content: content.split(','), title });
      }
    },

    viewDetail(record) {
      this.$router.push({
        path: `detail`,
        query: {
          id: record.training_id,
          o_id: record.org_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
