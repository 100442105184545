<template>
  <div class="full-size">
    <common-title :titles="titles"/>

    <collapse-training-info :id="id"/>

    <a-form-model layout="inline" class="ph-20">
      <a-form-model-item label="学员姓名">
        <a-input v-model="name" placeholder="请输入关键字"/>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button @click="onReset">重置</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="exportExcel">导出</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="back">返回</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="flex table-wrapper ph-20">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">

        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="evaluate(record)">内训评价</a-button>
          <a-button type="link" @click="viewStudentProgress(record)">学员进度明细</a-button>
          <a-button type="link" @click="viewMaterial(record)">回传材料明细</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <full-content-modal ref="fullContentModalRef"/>
    <grade-modal ref="gradeModalRef" @changed="onRefreshTable"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  getOrgStudentDetailList,
  getOrgStudentDetailListDownload,
} from '@/api/training/Index';
import Common from '@/utils/Common';

import Pagination, { getPagination } from '@components/Pagination.vue';
import GradeModal from '../../components/GradeModal';
import FullContentModal from '../../components/FullContentModal.vue';
import CommonTitle from '@/components/CommonTitle.vue';
import CollapseTrainingInfo from '../../components/CollapseTrainingInfo';

export default {
  name: 'StudentDetail',
  components: {
    GradeModal,
    FullContentModal,
    Pagination,
    CommonTitle,
    CollapseTrainingInfo,
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '内训计划列表' }, { title: '内训详情' }, { title: '学员进度明细' }],

      downloading: false,
      loading: false,
      id: 0,
      orgIds: '',
      name: '',

      columns: [],
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.id = this.$route.query?.id || 0;
    this.orgIds = this.$route.query?.o_id || '';

    this.onSearch();
  },
  methods: {
    back() {
      this.$router.back();
    },
    onReset() {
      this.name = '';

      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshTable() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize);

      const data = await getOrgStudentDetailList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      this.data = result.map((i, idx) => ({ ...i, id: i.id || (idx + '') }));

      const columns = data?.meta?.columns || [];
      columns.forEach((c) => {
        console.log(c);
        // if (c[`${c.key}_color`] !== undefined && c[`${c.key}_color`] !== null) {
        //   console.log('color');
        c.customCell = (r, i) => this.customCell(c.key, r, i);
        // }
      });
      this.columns = columns;

      this.pagination = Common.updatePagination(this.pagination, data?.meta?.pagination || {});
    },
    getParams(page, pageSize, down) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      const params = {
        training_id: this.id,
        org_id: this.orgIds,
        name: this.name,
        page: page,
        per_page: pageSize,
      };
      if (down) {
        params.down = 1;
      }
      return params;
    },
    async exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = this.getParams(this.pagination.current, this.pagination.pageSize, 1);
      const data = await getOrgStudentDetailListDownload(params).finally(() => this.downloading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `导出失败`);
        return;
      }
      this.$message.success(data?.message || '导出成功');
    },

    customCell(columnKey, record) {
      // const color = record[`${columnKey}_color`] === 0 ? 'green' : (record[`${columnKey}_color`] === 1 ? 'red' : 'auto');
      // const fontWeight = record[`${columnKey}_color`] ? '700' : '400';

      const color = record[`${columnKey}_color`] || 'auto';

      return {
        style: {
          color,
          // 'font-weight': fontWeight,
        },
        props: {},
        on: {},
      };
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content: content.split(','), title });
      }
    },

  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  padding-top: 15px;
}
</style>
