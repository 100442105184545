<template>
  <div class="cloud-verfy">
    <div class="header-breadcrumb">
      <CommonTitle :titles="titles"> </CommonTitle>
    </div>
    <div class="search-list">
      <div class="search-item">
        <span class="search-item-title">标题:</span>
        <span class="search-item-input">
          <a-input placeholder="请输入关键字"
                   v-model="param.title"
                   class="search-item-input" />
        </span>
      </div>
      <div class="search-item">
        <span class="search-item-title">起止日期:</span>
        <span class="search-item-input">
          <a-range-picker @change="getDate"
                          v-model="defaultDate"
                          class="search-item-input"></a-range-picker>
        </span>
      </div>

      <div class="search-item">
        <span class="search-item-title">组织机构:</span>
        <span class="search-item-input">
          <SelectOrg class="search-item-selectorg"
                     ref="selectOrg"
                     :show-label="false"
                     :disabled="read_only"
                     @select="selectOrg"
                     v-model="orgIds"></SelectOrg>
        </span>
      </div>
      <div class="search-item">
        <a-button type="primary"
                  class="search-item-btn"
                  @click="getList(1,10)">
          搜索
        </a-button>
        <a-button class="search-item-btn"
                  @click="clearSearch">重置</a-button>
      </div>
    </div>

    <div class="tc-list">
      <div class="tc-list"
           v-show="expandedRowKeys.length > 0">
        <a-button type="primary"
                  class="search-item-btn"
                  @click="verifysYes">
          批量审核通过
        </a-button>
        <a-button type="primary"
                  class="search-item-btn"
                  @click="verifysNo">
          批量审核拒绝
        </a-button>
      </div>
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               row-key="id"
               :rowSelection="rowSelection"
               :expanded-row-keys.sync="expandedRowKeys"
               :loading="isLoading"
               bordered>
        <span slot="title1"
              slot-scope="text, item">

          <a :href=" isOffice(item) ?  `https://view.officeapps.live.com/op/view.aspx?src=${item.resource_url}` :item.resource_url "
             target="_blank">{{ text }}</a>
        </span>
        <span slot="is_pass"
              slot-scope="text, record">
          <span v-if="record.is_pass == 0">未审核</span>
          <span v-if="record.is_pass == 1">已审核</span>
          <span v-if="record.is_pass == 2">已拒绝</span>
        </span>
        <span slot="pass_at_1"
              slot-scope="text, record">
          <span v-if="record.pass_at_1 === ''"
                class="w80">
            <a-divider />
          </span>
          <span v-if="record.pass_at_1 !== ''">{{ text }}</span>
        </span>

        <span slot="id"
              slot-scope="text, item"
              class="table-btn-list"
              v-show="item.is_pass == 0">
          <a @click="verifyYes(item)"
             class="table-btn-item"
             :disabled="isdisabled">通过</a>
          <a @click="verifyNo(item)"
             class="table-btn-item"
             :disabled="isdisabled">拒绝</a>
        </span>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
      <!-- <div>
        <span class="tc-paging">
          <a-pagination
            :current="pagination.current"
            :total="pagination.total"
            @change="getPage"
        /></span>
      </div> -->
    </div>
    <!-- <a-modal
      title="提示"
      :visible="show_model.visible"
      :confirm-loading="show_model.confirmLoading"
      @ok="showModelOk"
      @cancel="showModelOkCancel"
    >
      <p>{{ show_model.model_text }}</p>
    </a-modal> -->
    <!-- <LoadingContent v-show="isLoading"></LoadingContent>
    <Loading v-show="isLoading"></Loading> -->
  </div>
</template>

<script>
import SelectOrg from '@components/SelectOrg';
// import LoadingContent from "@/components/LoadingContent.vue";
// import Loading from "../components/Loading.vue";
import CommonTitle from '@components/CommonTitle.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';
import Common from '@/utils/Common';
import {
  getCloudAudiList,
  postCloudPass,
  postCloudNoPass,
} from '@/api/training/Cloud.js';

let titles = [
  {
    title: '内训管理',
  },
  {
    title: '公开云管理',
  },
  {
    title: '公开申请审核',
  },
];

let param = {
  title: '',
  start_at: '',
  end_at: '',
  org_id: '',
  page: 1,
  per_page: 10,
};

const columns = [
  {
    title: '标题',
    key: 'title',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title1' },
    align: 'center',
  },
  {
    title: '类型',
    dataIndex: 'resource_type_str',
    key: 'resource_type_str',
    align: 'center',
  },
  {
    title: '大区',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    align: 'center',
  },
  {
    title: '店名',
    key: 'org_name',
    dataIndex: 'org_name',
    align: 'center',
  },
  {
    title: '申请人',
    key: 'truename',
    dataIndex: 'truename',
    align: 'center',
  },
  {
    title: '电话',
    key: 'username',
    dataIndex: 'username',
    align: 'center',
  },
  {
    title: '申请时间',
    key: 'public_at_1',
    dataIndex: 'public_at_1',
    align: 'center',
  },
  {
    title: '审核状态',
    key: 'is_pass',
    dataIndex: 'is_pass',
    scopedSlots: { customRender: 'is_pass' },
    align: 'center',
  },
  {
    title: '审核时间',
    key: 'pass_at_1',
    dataIndex: 'pass_at_1',
    scopedSlots: { customRender: 'pass_at_1' },
    align: 'center',
    width: 100,
  },
  {
    title: '操作',
    key: 'id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'center',
  },
];

let show_model = {
  visible: false,
  confirm_loading: false,
  model_text: '',
  id: 0,
  isOk: 0,
};

export default {
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: (this.expandedRowKeys || []),
        onChange: (selectedRowKeys) => {
          this.expandedRowKeys = selectedRowKeys;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.is_pass > 0, // Column configuration not to be checked
          },
        }),
      };
    },
  },
  data () {
    return {
      titles,
      orgIds: '',
      read_only: false,
      default_selectedIds: '',
      defaultDate: ['', ''],
      param,
      columns,
      data: [],
      loading: false,
      pagination: getPagination(),
      expandedRowKeys: [],
      show_model,
      isLoading: false,
      isdisabled: false,
    };
  },
  components: {
    SelectOrg,
    CommonTitle,
    Pagination,
  },
  methods: {
    getDate (date, dateString) {
      console.log(date, dateString);
      this.param.start_at = dateString[0];
      this.param.end_at = dateString[1];
    },
    selectOrg (event) {
      const data = event?.data || [];
      this.param.org_id = data.map((i) => i.id).join(',');
      console.log(this.orgIds);
    },
    handleChange (value) {
      this.resource_type = value;
    },
    getList (page, pageSize) {
      this.isLoading = true;
      this.param.page = page || 1;
      this.param.per_page = pageSize || 10;
      getCloudAudiList(this.param).then((res) => {
        if (res.error_code === 0) {
          this.isLoading = false;
          this.data = res.data;
          const pagination = res?.meta?.pagination || {};
          this.pagination = Common.updatePagination(
            this.pagination,
            pagination
          );
        }
      });
    },
    verifyYes (item) {
      let text = `["${item.title}"]确认要审核通过吗?`;
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          postCloudPass({ cloud_log_ids: item.id }).then((res) => {
            if (res.error_code >= 0) {
              this.$message.success(`审核成功`);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
            if (res.error_code < 0) {
              this.$message.error(`审核失败`, res.message);
            }
          });
        },
      });
    },
    verifysYes () {
      let text = `确认要将已选择项审核通过吗`;
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          postCloudPass({ cloud_log_ids: `${this.expandedRowKeys}` }).then(
            (res) => {
              if (res.error_code >= 0) {
                this.$message.success(`审核成功`);
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }
              if (res.error_code < 0) {
                this.$message.error(`审核失败`, res.message);
              }
            }
          );
        },
      });
    },

    verifyNo (item) {
      let text = `["${item.title}"]确认要审核拒绝吗?`;
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          postCloudNoPass({ cloud_log_ids: item.id }).then((res) => {
            if (res.error_code >= 0) {
              this.$message.success(`审核成功`);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
            if (res.error_code < 0) {
              this.$message.error(`审核失败`, res.message);
            }
          });
        },
      });
    },
    verifysNo () {
      let text = `确认要将已选择项审核拒绝吗`;
      this.$confirm({
        content: () => <div>{{ text }}</div>,
        onOk: () => {
          postCloudNoPass({ cloud_log_ids: `${this.expandedRowKeys}` }).then(
            (res) => {
              if (res.error_code === 0) {
                this.$message.success(`审核成功`);
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }
              if (res.error_code < 0) {
                this.$message.error(`审核失败`, res.message);
              }
            }
          );
        },
      });
    },
    getPage (pagination) {
      if (this.pagination.current_page !== pagination.current) {
        this.pagination.current_page = pagination.current;
        this.getList(1, this.pagination.pageSize);
      }
      // console.log(pagination.current);
    },
    getId (item) {
      console.log(item);
    },
    clearSearch: function () {
      this.param = {
        title: '',
        start_at: '',
        end_at: '',
        org_id: '',
        page: 1,
        per_page: 10,
      };
      this.defaultDate = ['', ''];
      this.orgIds = '';
      this.getList(1, 10);
    },
    saveShowModel: function () {
      let that = this;
      return new Promise(function (resolve) {
        let interval = setInterval(function () {
          if (that.show_model.isOk !== 0) {
            clearInterval(interval);
            if (that.show_model.isOk === 1) {
              resolve(1);
            } else {
              resolve(2);
            }
          }
        }, 500);
      });
    },
    onPageChange (page, pageSize) {
      this.getList(page, pageSize);
    },
    onSizeChange (current, size) {
      this.getList(1, size);
    },
    isOffice (item) {
      return (
        item.resource_type === 1 ||
        item.resource_type === 2 ||
        item.resource_type === 4
      );
    },
  },
  created () {
    this.clearSearch();
  },
};
</script>

<style scoped>
.cloud-verfy {
  position: relative;
}
.search-list {
  margin: 10px;
  display: flex;
  /* width: 100%; */
  flex-wrap: wrap;
}
.search-item {
  display: flex;
  vertical-align: middle;
  line-height: 40px;
  margin: 10px;
  text-align: left;
}
.search-item-title {
  vertical-align: middle;
}
.search-item-input {
  margin: 0 10px;
  flex: 6;
}
.search-item-btn {
  margin-top: 3px;
}
.search-item-selectorg {
  margin: 3px 10px;
}
.tc-list {
  margin: 15px;
}
.table-btn-list {
  display: flex;
  /* width: 100%; */
  flex-wrap: wrap;
}
.table-btn-item {
  flex: 1;
  min-width: 20%;
}
.tc-paging {
  display: block;
  text-align: center;
  margin-top: 10px;
}
.ant-table-title {
  display: none !important;
}
.w80 {
  display: inline-block;
  width: 60px;
}
</style>
