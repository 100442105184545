<template>
  <create-training-basic training-type="2"/>
</template>

<script>
import CreateTrainingBasic from '../components/CreateTrainingBasic.vue';

export default {
  name: 'Basic',
  components: { CreateTrainingBasic },
};
</script>

<style scoped lang="scss">

</style>
