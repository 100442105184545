<template>
  <div class="full-size auto-y">
    <CommonTitle :titles="titles">
      <div slot="buttons">
        <a-button type="primary" class="pos-relative ph-20"
                  @click.stop="createTrainingMenuShown = !createTrainingMenuShown">
          <span>创建内训</span>

          <a-menu v-if="createTrainingMenuShown" theme="light" class="create-menu"
                  @click="selectCreateMenu">
            <a-menu-item key="1">统一内训</a-menu-item>
            <a-menu-item key="2">自主内训</a-menu-item>
          </a-menu>
        </a-button>

      </div>
    </CommonTitle>

    <div class="pd-card">
      <a-form-model layout="inline">
        <a-form-model-item label="内训主题">
          <a-input v-model="form.training_title" placeholder="请输入关键字"></a-input>
        </a-form-model-item>
        <a-form-model-item label="起止日期">
          <a-range-picker v-model="form.date"></a-range-picker>
        </a-form-model-item>
        <a-form-model-item label="参与岗位">
          <select-job-pure v-model="form.post_ids_str"></select-job-pure>
        </a-form-model-item>
        <a-form-model-item label="参与组织机构">
          <select-org v-model="form.org_ids_str" :show-label="false"></select-org>
        </a-form-model-item>
        <a-form-model-item label="结算状态">
          <select-settlement-status v-model="form.is_settlement"></select-settlement-status>
        </a-form-model-item>
        <a-form-model-item label="内训状态">
          <select-unified-status v-model="form.is_status"></select-unified-status>
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select v-model="form.training_type"
                    class="select">
            <a-select-option :value="-1">不限</a-select-option>
            <a-select-option :value="1">统一内训</a-select-option>
            <a-select-option :value="2">自主内训</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" class="mr-60" @click="onSearch()">查询</a-button>
          <a-button @click="onReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="flex flex-end pd-card">
      <a-button @click="batchSettle">批量结算</a-button>
      <a-button type="primary" :loading="downloading" @click="exportExcel()">导出</a-button>
    </div>

    <div class="flex pd-card">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               :row-selection="rowSelection"
               row-key="id" class="table small-cell-table">

        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="editRecord(record)">
            <span v-if="record.is_publish">查看</span>
            <span v-else>编辑</span>
          </a-button>
          <a-button v-if="record.is_publish" type="link" @click="viewDetail(record)">经销商详情</a-button>
          <a-button v-if="record.is_settlement" type="link" @click="viewScore(record)">评分表</a-button>
          <a-button v-else type="link" @click="settleTraining(record)">结算评分</a-button>
          <a-button type="link" @click="deleteRecord(record)">删除</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <full-content-modal ref="fullContentModalRef"/>
  </div>
</template>

<script>
import {
  getUnifiedTrainingList,
  deleteUnifiedTraining,
  saveSettlement,
  getUnifiedTrainingListDownload,
} from '@/api/training/Index';
import Common from '@/utils/Common';

import { menus as UnifiedMenus } from './unified/Detail.vue';
import { menus as IndependentMenus } from './independent/Detail.vue';

import SelectOrg from '@/components/SelectOrg.vue';
import SelectJobPure from '@/components/SelectJobPure.vue';
import CommonTitle from '@components/CommonTitle.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';
import SelectSettlementStatus from '../components/SelectSettlementStatus.vue';
import SelectUnifiedStatus from '../components/SelectUnifiedStatus.vue';
import FullContentModal from '../components/FullContentModal.vue';
import { Modal } from 'ant-design-vue';

const defaultQuery = {
  training_title: '',
  date: [undefined, undefined],
  post_ids_str: '',
  org_ids_str: '',
  is_settlement: -1,
  is_status: -1,
  training_type: -1,
};

export default {
  name: 'List',
  components: {
    CommonTitle,
    Pagination,
    SelectOrg,
    SelectJobPure,
    SelectSettlementStatus,
    SelectUnifiedStatus,
    FullContentModal,
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: (this.selectedRows || []).map((i) => i.id),
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: !!record.is_settlement,
          },
        }),
      };
    },
  },
  data() {
    return {
      titles: [{ title: '内训管理' }, { title: '内训计划列表' }],

      createTrainingMenuShown: false,

      downloading: false,
      loading: false,
      columns: [
        {
          width: '120px',
          align: 'center',
          dataIndex: 'title',
          key: 'title',
          title: '内训主题',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'type_title',
          key: 'type_title',
          title: '类型',
        },
        {
          width: '80px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'training_post_str',
          key: 'training_post_str',
          title: '参与岗位',
          customCell: (record) => {
            return {
              style: {
                width: '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record['training_post_str'], '参与岗位详情'),
              },
            };
          },
        },
        {
          width: '100px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'regional_org_name_str',
          key: 'regional_org_name_str',
          title: '大区',
          customCell: (record) => {
            return {
              style: {
                width: '100px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record['regional_org_name_str'], '大区详情'),
              },
            };
          },
        },
        {
          width: '120px',
          align: 'center',
          ellipsis: true,
          dataIndex: 'training_org_str',
          key: 'training_org_str',
          title: '体验中心名称',
          customCell: (record) => {
            return {
              style: {
                width: '180px',
                cursor: 'pointer',
              },
              props: {},
              on: {
                click: () => this.showFullContent(record['training_org_str'], '体验中心名称详情'),
              },
            };
          },
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'is_status_title',
          key: 'is_status_title',
          title: '内训状态',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'is_settlement_title',
          key: 'is_settlement_title',
          title: '结算状态',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'settlement_time_1',
          key: 'settlement_time_1',
          title: '结算时间',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'starttime_1',
          key: 'starttime_1',
          title: '开始时间',
        },
        {
          width: '80px',
          align: 'center',
          dataIndex: 'endtime_1',
          key: 'endtime_1',
          title: '结束时间',
        },
        {
          width: '120px',
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],

      selectedRows: [],
      pagination: getPagination(),

      form: { ...defaultQuery },
    };
  },
  created() {
    this.onSearch();
  },
  mounted() {
    document.addEventListener('click', this.hideCreateMenu, false);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideCreateMenu);
  },
  methods: {
    hideCreateMenu() {
      this.createTrainingMenuShown = false;
    },
    selectCreateMenu({ key } = {}) {
      let path = '';
      switch (key) {
        case '1':
          path = UnifiedMenus[0].path || '';
          break;
        case '2':
          path = IndependentMenus[0].path || '';
          break;
        default:
      }

      this.$router.push({
        path,
      });
    },

    onReset() {
      this.form = { ...defaultQuery };

      this.onSearch();
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onRefreshPage() {
      if (this.data?.length - 1 <= 0 && this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
        this.getData(this.pagination.current > 1 ? (this.pagination.current - 1) : 1, this.pagination.pageSize);
      } else {
        this.getData(this.pagination.current, this.pagination.pageSize);
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      const params = this.getParams(page, pageSize);

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getUnifiedTrainingList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, down) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        ...this.form,
        start_day: this.form.date[0]?.format('YYYY-MM-DD') || '',
        end_day: this.form.date[1]?.format('YYYY-MM-DD') || '',
        page: page,
        per_page: pageSize,
      };
      delete params.date;
      if (down) {
        params.down = 1;
      }

      return params;
    },
    async exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = this.getParams(1, this.pagination.pageSize, 1);
      const data = await getUnifiedTrainingListDownload(params).finally(() => this.downloading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || `导出失败`);
        return;
      }
      this.$message.success(data?.message || '导出成功');
    },

    deleteRecord(record) {
      this.$confirm({
        content: '确认删除该内训？',
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    async confirmDeleteRecord(record) {
      this.loading = true;
      const data = await deleteUnifiedTraining(record.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      this.onRefreshPage();
    },

    showFullContent(content, title) {
      if (!content) {
        return;
      }
      if (this.$refs.fullContentModalRef?.show) {
        this.$refs.fullContentModalRef.show({ content: content.split(','), title });
      }
    },

    editRecord(record) {
      let path = '';
      switch (+record.type) {
        case 1:
          path = '/index/training/unified/unified';
          break;
        case 2:
          path = '/index/training/unified/independent';
          break;
        default:
      }
      this.$router.push({
        path,
        query: {
          id: record.id,
        },
      });
    },

    // 批量结算
    batchSettle() {
      if (!this.selectedRows?.length) {
        this.$message.info('请选择要结算的内训');
        return;
      }
      const ids = this.selectedRows.map((i) => i.id);

      const noScores = [];
      this.selectedRows.forEach((i) => {
        if (i.not_manager_score) {
          noScores.push(i);
        }
      });

      const noScoresContent = '还有未进行评价打分的经销商，结算后评分无法更改，经销商未完成内容分数无法追加，是否确定结算？';
      const scoredContent = '结算后，评分无法更改，经销商未完成内容分数无法追加，是否确定结算？';

      const content = (h) => h('div', [
        h('div', {
          style: {
            'margin-bottom': '20px',
            'font-size': '14px',
            'color': '#333333',
          },
        }, noScores?.length ? noScoresContent : scoredContent),
        noScores?.length ? h('div', {
          style: {
            'margin-top': '20px',
            'margin-bottom': '10px',
            'color': '#333333',
          },
        }, '未进行评价打分的内训：') : null,
        h('div', {
          style: {
            'overflow-y': 'auto',
            'max-height': '280px',
          },
        }, [
          ...noScores.map((i) => h('div', {
            style: {
              'margin-bottom': '5px',
            },
          }, i.title)),
        ]),
      ]);

      Modal.confirm({
        title: '提示',
        maskClosable: true,
        content,
        onOk: () => this.settle(ids, true),
      });
    },
    // 单个结算
    settleTraining(record) {
      let content = '';
      if (record.not_manager_score) {
        content = '还有未进行评价打分的经销商，结算后评分无法更改，经销商为完成内容分数无法追加，是否确定结算？';
      } else {
        content = '结算后，评分无法更改，经销商为完成内容分数无法追加，是否确定结算？';
      }
      Modal.confirm({
        title: '提示',
        maskClosable: true,
        content,
        onOk: () => this.settle([record.id]),
      });
    },
    async settle(ids, isBatch) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await saveSettlement({
        training_ids: (ids || []).join(','),
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '结算失败');
        return;
      }
      this.$message.success(data?.message || '结算成功');
      if (isBatch) {
        this.selectedRows = [];
      }
      this.onRefreshPage();
    },

    // 查看评分表
    viewScore(record) {
      this.$router.push({
        path: '/index/training/unified/detail/score',
        query: {
          id: record.id,
        },
      });
    },

    viewDetail(record) {
      this.$router.push({
        path: `/index/training/unified/detail`,
        query: {
          id: record.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.create-menu {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  z-index: 99;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 10px #E5E5E5, 0 0 10px #E5E5E5;
}

.select {
  min-width: 150px;
}
</style>
