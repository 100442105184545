<template>
  <create-training-confirm training-type="1"/>
</template>

<script>
import CreateTrainingConfirm from '../components/CreateTrainingConfirm.vue';

export default {
  name: 'Confirm',
  components: { CreateTrainingConfirm },
};
</script>

<style scoped lang="scss">

</style>
