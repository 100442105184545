<template>
  <CreateTrainingScience training-type="1"/>
</template>

<script>
import CreateTrainingScience from '../components/CreateTrainingScience.vue';

export default {
  name: 'Science',
  components: { CreateTrainingScience },
};
</script>

<style scoped lang="scss">

</style>
